import React, { useRef, useEffect,useState } from "react";
import styled, { css } from "styled-components";
import { observer } from "mobx-react-lite";
import { useCrewCalender } from "../StoreCrewCalender";
import WeekToolbar from "./WeekToolbar";
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle'
import MenuRoundedIcon from '@material-ui/icons/MenuRounded'
interface Props {
  hidden: boolean;
  shiftNoteStyle: string;
  handleShiftToggle(): void,
  shiftCollapsed: boolean;

}
function CalendarToolbar({ hidden, shiftNoteStyle,handleShiftToggle ,shiftCollapsed}: Props) {
  const [shiftNewStyle, setShiftNewStyle] = useState<string>('shiftNoteCollapseIn')
  const [rotation, setRotation] = useState<string>('0deg')
  const [topDiv, setTopDiv] = useState<string>('20px')
  

  const [topDivShift, setTopDivShift] = useState<string>('20px')
  const { isWeekView, crewNameWidth, gridNumber, fullScreen } =
    useCrewCalender();
  let content;
  if (isWeekView) {
    content = (
      <WeekToolbar
        hidden={hidden}
        shiftNoteStyle={shiftNoteStyle}
        shiftNewStyle={shiftNewStyle}
      />
    );
  }
  const handleToggle = () => {
    if (shiftNewStyle == 'shiftNoteCollapseIn') {
     setShiftNewStyle('shiftNoteCollapseOut');
     setRotation('270deg');
     setTopDiv('22px');  
     return; 
    } else {
      setShiftNewStyle('shiftNoteCollapseIn');
      setRotation('0deg'); 
      setTopDiv('20px');
      return;
    }
  }
  
  useEffect(() => {
    if(shiftNoteStyle == 'shfitNoteClass') {
      setTopDivShift('0px');
     
    }else{
      setTopDivShift('20px');
     
    }
    // console.log(shiftNoteStyle);
  }, [shiftNoteStyle])
  return (
    <Wrapper id="mainWrapperElement">
      <Offsetter crewNameWidth={crewNameWidth} >
      <CollapsibleDiv id="CollapsibleDivNote"  onClick={handleToggle} style={{fontSize:'14px',borderRadius:'0px',cursor:'pointer',display:'inline-flex',top:topDiv}}>
        <ArrowDropDownCircleIcon style={{transform:"rotate("+rotation+")"}} className={shiftNoteStyle} />
        <CollapsibleDivTxt id="CollapsibleDivTxt"> </CollapsibleDivTxt>
        </CollapsibleDiv>
        <CollapsibleDiv id="CollapsibleDivShift"  onClick={handleShiftToggle} style={{fontSize:'14px',borderRadius:'0px',cursor:'pointer',display:'inline-flex',top: topDivShift}}>
          <MenuRoundedIcon style={{color: !shiftCollapsed ? '#009688' : '#000' }}   />
        </CollapsibleDiv> 
      </Offsetter>
      <ContentWrapper>{content}</ContentWrapper>
    </Wrapper>
  );
}

const CollapsibleDiv = styled.div`
height: 32px;
width:40px;
z-index: 555;
position:relative;
left:0;
display:inline-flex;
font-size:12px;
border-radius:0;
`
const CollapsibleDivTxt = styled.div`
  margin-top: 5px;`
const Wrapper = styled.div`
  display: flex;
  position: sticky;
  top: 85px;
  z-index: 10;
`;
const Offsetter = styled.div<{ crewNameWidth: number }>`
  flex-grow: 0;
  max-width: ${(props) => `${props.crewNameWidth}px`};
  flex-basis: ${(props) => `${props.crewNameWidth}px`};
  background-color: #fff;
`;
const ContentWrapper = styled.div`
  /* width: 87.5%; */
  /* background-color: #F5F5F5; */
  flex-grow: 1;
`;
export default observer(CalendarToolbar);
