import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import _reject from 'lodash/reject'
import _merge from 'lodash/merge'
import _isEmpty from 'lodash/isEmpty'
import _unionBy from 'lodash/unionBy'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import ProjectAutocomplete from '@src/components/autocompletes/ProjectAutocomplete'
import { Shift } from '@src/types/Shift'
import { Project } from '@src/types/project/Project'
import { ProjectStatus } from '@src/types/project/ProjectStatus'
import FormModalTextField from '@src/components/FormModal/Textfield'
import FormModalTextFieldReadOnly from '@src/components/FormModal/TextfieldReadOnly'
import FormModalDatePicker from '@src/components/FormModal/DatePicker'
import FormModalSelect from '@src/components/FormModal/Select'
import FormModalRadioGroup from '@src/components/FormModal/RadioGroup'
import { ShiftTypeId, formatShiftTypeId } from '@src/types/ShiftTypeId'
import { Crew } from '@src/types/Crew'
import FormModalCheckboxGroup from '@src/components/FormModal/CheckboxGroup'
import { ProjectTypeId, formatProjectTypeId } from '@src/types/ProjectTypeId'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import { apiShiftCreate } from '@src/apis/shift-create'
import { useAppStore } from '@src/AppStore'
import { apiShiftUpdate } from '@src/apis/shift-update'
import { MaterialAsphaltProduct } from '@src/types/MaterialAsphaltProduct'
import { DRSMaterialSpraySealItem } from '@src/types/drs/material/MaterialSpraySealItem'
import ShiftShortActionsMenu from './ShiftShortActionsMenu'
import DRSRedirectConfirmDialog from './DRSRedirectConfirmDialog'
import { apiShiftGetPack } from '@src/apis/shift-get-pack'
import { DRSFormCard } from '@src/types/DRSFormCard'
import { PackFormType } from '@src/types/PackFormType'
import useEditShiftRules from '@src/utils/hooks/useEditShiftRules'
import usePermission from '@src/utils/hooks/usePermission'
import { formatDRSStatus } from '@src/types/drs/DRSStatus'
import moment from 'moment'
import DRSSectionMaterialAspahltItems from '@src/modules/DRS/parts/3-Material/parts/MaterialAsphaltItems'
import DRSSectionMaterialAspahltOthers from '@src/modules/DRS/parts/3-Material/parts/MaterialAsphaltOther'
import DRSSectionMaterialSpraySealItems from '@src/modules/DRS/parts/3-Material/parts/MaterialSpraySealItems'
import DRSSectionMaterialSpraySealOthers from '@src/modules/DRS/parts/3-Material/parts/MaterialSpraySealOther'
import { useDRS } from '@src/modules/DRS/useDRS'
import { DRSPageType } from '@src/modules/DRS/DRSPage'
import { useCrewCalender } from '../StoreCrewCalender'
import _union from 'lodash/union'
import _find from 'lodash/find'
import _some from 'lodash/some'
import { getLength } from '@src/utils/validateLength'
import DRSSectionComment from '@src/modules/DRS/parts/3-Material/parts/DRSSectionComment'

import CompThemeButton from '@src/components/Buttons/CompThemeButton'
import SealRequirementDialog from './SealRequirementDialog'

interface Props {
  open: boolean,
  onClose(): void,
  onSave(): void,
  data: null | Shift,
  crewOptions: Crew[],
  originCrew: Crew,
  originDate: string,
}

const CompShiftDialog = (props: Props) => {
  const {
    open,
    onClose,
    onSave,
    data,
    crewOptions,
    originCrew,
    originDate,
  } = props
  const isAdd = data === null
  const isEdit = !isAdd
  const { alertDone, alertError, alert } = useSnackbar()
  const { serialId } = useAppStore()
  const [project, setProject] = useState<null | Project>(null)
  const [street, setStreet] = useState<string>('')
  const [suburb, setSuburb] = useState<string>('')
  const [nearestCrossStreet, setNearestCrossStreet] = useState<string>('')
  const [sealData, setSealData] = useState<string>('')
  const [shiftSaleOrder, setShiftSaleOrder] = useState<string>('')
  const [isCostOnlyShift, setIsCostOnlyShift] = useState<boolean>(false)
  const [kms, setKms] = useState<string>('')
  const [date, setDate] = useState<string>('')
  const [shiftTypeId, setShiftTypeId] = useState<null | ShiftTypeId>(null)
  const [asphaltProducts, setAsphaltProducts] = useState<MaterialAsphaltProduct[]>([])
  const [spraySealProducts, setSpraySealProducts] = useState<DRSMaterialSpraySealItem[]>([])
  const [drsId, setDRSId] = useState<string>('')
  const [drsPageType, setDrsPageType] = useState<DRSPageType>(-1)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [drsDialogOpen, setDrsDialogOpen] = useState<boolean>(false)
  const [sealDialogOpen, setSealDialogOpen] = useState<boolean>(false)
  const [drsFormCard, setDrsFormCard] = useState<DRSFormCard | undefined>()
  const [isSaving, setIsSaving] = useState<any>(false)
  const [canShiftDateBeChanged, canShiftCrewBeChanged] = useEditShiftRules(data)
  const { canEditShift, canPlanDRS, canEditDRS, canReviewDRS } = usePermission()
  const { drs, loading, fetchDRS, resetDRS } = useDRS()
  const { drss, setDrss } = useCrewCalender()
  const [refreshTrigger, setRefreshTrigger] = useState<number>(0)
  const [fieldsInError, setFieldsInError] = useState<any[]>([])

  let validateStreet: any = null, validateNearest: any = null, validateSubrub: any = null;

  useEffect(() => {
    resetDRS()
    if (canPlanDRS) {
      setDrsPageType(DRSPageType.Plan)
    } else if (canEditDRS) {
      setDrsPageType(DRSPageType.Edit)
    } else if (canReviewDRS) {
      setDrsPageType(DRSPageType.Review)
    }
  }, [])
  useEffect(() => {
    if (drsId && DRSPageType[drsPageType]) {
      fetchDRS(drsId, drsPageType)
    }
  }, [drsId, drsPageType])

  const handleOnUpdated = () => {
    setRefreshTrigger(refreshTrigger + 1)
  }
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const ShiftTypeOptions = [
    {
      value: ShiftTypeId.DAY,
      label: formatShiftTypeId(ShiftTypeId.DAY),
    },
    {
      value: ShiftTypeId.NIGHT,
      label: formatShiftTypeId(ShiftTypeId.NIGHT),
    },
  ]
  const [projectTypeIds, setProjectTypeIds] = useState<(ProjectTypeId.Asphalt | ProjectTypeId.SpraySeal)[]>([])
  const PROJECT_TYPE_OPTIONS = [
    {
      value: ProjectTypeId.Asphalt,
      label: formatProjectTypeId(ProjectTypeId.Asphalt),
    },
    {
      value: ProjectTypeId.SpraySeal,
      label: formatProjectTypeId(ProjectTypeId.SpraySeal),
    },
  ]
  const [crew, setCrew] = useState<null | Crew>(null)
  const CREW_OPTIONS = crewOptions.map((crew) => {
    return {
      ...crew,
      value: crew.id,
      label: crew.name,
    }
  })
  console.log(`CREW_OPTIONS`)
  console.log(CREW_OPTIONS)
  console.log(`CREW_OPTIONS`)
  useEffect(() => {
    setCrew(originCrew)
    setDate(originDate)
  }, [])
  useEffect(() => {
    if (data !== null) {
      setProject({
        projectInternalId: data.projectInternalId,
        id: data.projectId,
        name: data.projectName,
      })
      let finalProjectTypeIds: (ProjectTypeId.Asphalt | ProjectTypeId.SpraySeal)[] = []
      if (data.drsProjectType.id === ProjectTypeId.AsphaltAndSpraySeal) {
        finalProjectTypeIds = [ProjectTypeId.Asphalt, ProjectTypeId.SpraySeal]
      } else if (data.drsProjectType.id === ProjectTypeId.Asphalt) {
        finalProjectTypeIds = [ProjectTypeId.Asphalt]
      } else {
        finalProjectTypeIds = [ProjectTypeId.SpraySeal]
      }

	  console.log('My data ->>>' + JSON.stringify(data))


      setProjectTypeIds(finalProjectTypeIds)
      setShiftTypeId(data.plan.shiftType.id)
      setStreet(data.plan.street)
      setSuburb(data.plan.suburb)
      setNearestCrossStreet(data.plan.nearestCrossStreet)
      setAsphaltProducts(data.products.asphalt)
      setSpraySealProducts(data.products.spraySeal)
      setDRSId(data.drsId.toString())
	  setKms(data.plan.kmPlantToDepot);
      if(data.ShiftSalesOrder)
          setShiftSaleOrder(data.ShiftSalesOrder);
	  if(data.IsCostOnlyShift)
		setIsCostOnlyShift(data.IsCostOnlyShift);
      if(data.ShiftSealingRequirment)
        setSealData(data.ShiftSealingRequirment);
      

    }
  }, [data])
  useEffect(() => {
    const fetchPack = async () => {
      try {
        if (data !== null) {
          const result = await apiShiftGetPack({
            shiftId: data.id.toString(),
            serialId,
          })

          // dispatching forms to drs, sif ... and all other available forms
          if (result.data && result.data.forms) {
            setDrsFormCard(undefined)
            result.data.forms.forEach((form: any) => {
              switch (form.formType) {
                case PackFormType.DAILY_REPORTY_SHEET:
                  setDrsFormCard(form)
                  return
              }
            })
          }
        }
      } catch (e) {

      }
    }
    fetchPack()
  }, [])
  function getProjectTypeId(ids: (ProjectTypeId.Asphalt | ProjectTypeId.SpraySeal)[]): ProjectTypeId {
    if (ids.length === 2) {
      return ProjectTypeId.AsphaltAndSpraySeal
    }
    return ids[0] === ProjectTypeId.Asphalt ? ProjectTypeId.Asphalt : ProjectTypeId.SpraySeal
  }
  const handleSave = async () => {

	console.log('IsCostOnlyShift ---> ' + isCostOnlyShift)
    if (!project) {
      alert({
        type: 'error',
        message: 'Project required',
      })
      return
    }
    if (!street) {
      alert({
        type: 'error',
        message: 'Street required',
      })
      return
    }
    if (!suburb) {
      alert({
        type: 'error',
        message: 'Suburb required',
      })
      return
    }
    if (!nearestCrossStreet) {
      alert({
        type: 'error',
        message: 'Nearest Cross Street required',
      })
      return
    }
    if (!crew) {
      alert({
        type: 'error',
        message: 'Crew required',
      })
      return
    }
    if (!shiftTypeId) {
      alert({
        type: 'error',
        message: 'Shift Time required',
      })
      return
    }
    if (!projectTypeIds.length) {
      alert({
        type: 'error',
        message: 'Shift Type required',
      })
      return
    }
    setIsSaving(true)
    if (isAdd) {
      try {
        apiShiftCreate({
          project: project,
          shiftTypeId: shiftTypeId,
          dates: [date],
          crewId: crew.id,
          drsProjectTypeId: getProjectTypeId(projectTypeIds),
          serialId,
          street,
          nearestCrossStreet,
          suburb,
          planKMPlantToDepot: parseFloat(kms),
          ShiftSealingRequirment:sealData,
          ShiftSalesOrder: shiftSaleOrder,
		  IsCostOnlyShift: isCostOnlyShift
        }).then((response: any) => {
          if (response.data && response.data.shifts && !_isEmpty(response.data.shifts)) {
            const newDrss: any[] = _merge([], drss, response.data.shifts)
            setDrss(newDrss)
          }
          alertDone()
          setIsSaving(false)
          resetDRS()
          onSave()
        })

      } catch (e) {
        alertError()
      }
    } else {
      if (data === null) {
        return
      }

      if (!moment(date, 'DD-MM-YYYY').isSame(originDate, 'day') && !canShiftDateBeChanged) {
        alert({
          type: 'error',
          message: `Date can not be changed when status is  ${formatDRSStatus(data.status.id)}`,
        })
        return
      }

      if (originCrew.id !== crew.id && !canShiftCrewBeChanged) {
        alert({
          type: 'error',
          message: `Crew can not be changed when status is  ${formatDRSStatus(data.status.id)}`,
        })
        return
      }

      try {
        apiShiftUpdate({
          shiftId: data.id,
          description: data.description,
          project: project,
          shiftTypeId: shiftTypeId,
          date: date,
          crewId: crew.id,
          drsProjectTypeId: getProjectTypeId(projectTypeIds),
          serialId,
          street,
          nearestCrossStreet,
          suburb,
          planKMPlantToDepot: parseFloat(kms),
          ShiftSealingRequirment:sealData,
          ShiftSalesOrder:shiftSaleOrder,
		  IsCostOnlyShift: isCostOnlyShift
        }).then((response: any) => {
          if (response.data) {
            const newDrss: any[] = _unionBy(drss, response.data.shifts, 'id')
            setDrss(newDrss)
          }
          alertDone()
          setIsSaving(false)
          onSave()
        })
      } catch (e) {
        alertError()
      }
    }
  }
  return <Dialog
    fullWidth={true}
    maxWidth={'xl'}
    open={open}
    onClose={onClose}
  >
    <DialogTitle >
      <ModalTitle>Shift</ModalTitle>
    </DialogTitle>
    <DialogContent>
      <SectionWrapper>
        <Section>
          <Title>Shift Information</Title>
          <FieldsWrapper>
            <FieldWrapper>
              {canEditShift ?
                <ProjectAutocomplete
                  label='Project Number'
                  selectedItem={project ? { value: project.id || `${project.projectInternalId}`, label: project.id ? project.id : `${project.projectInternalId} | Draft Project` } : { value: '', label: '' }}
                  fullWidth
                  disabled={isEdit}
                  status={[ProjectStatus.Draft, ProjectStatus.Active]}
                  onChange={(raw: Project) => setProject(raw)}
                />
                :
                <FormModalTextFieldReadOnly
                  type='text'
                  label='Project Number'
                  value={project ? project.id : null}
                />
              }
            </FieldWrapper>
            <FieldWrapper>
              {canEditShift ?
                <FormModalTextField
                  label='Street'
                  defaultValue={street}
                  helperText={
                    _some(fieldsInError, { key: 'Street' }) ?
                      _find(fieldsInError, { key: 'Street' }).helperTextError
                      : ""
                  }
                  error={_some(fieldsInError, { key: 'Street' })}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    var val = e.target.value;
                    if (validateStreet != null) {
                      clearTimeout(validateStreet)
                    }
                    validateStreet = setTimeout(() => {
                      if (getLength(val, 50)) {
                        setFieldsInError(_union(fieldsInError, [{ key: 'Street', helperTextError: "Max 50 characters allowed" }]))
                      } else {
                        setFieldsInError(_reject(fieldsInError, { key: 'Street' }))
                      }
                    }, 500)
                  }}
                  onBlur={(e) => {
                    setStreet(e.target.value)
                  }}
                /> :
                <FormModalTextFieldReadOnly
                  type='text'
                  label='Street'
                  value={street}
                />
              }
            </FieldWrapper>
            <FieldWrapper>
              {canEditShift ?
                <FormModalTextField
                  label='Nearest Cross Street'
                  defaultValue={nearestCrossStreet}
                  helperText={
                    _some(fieldsInError, { key: 'Nearest Cross Street' }) ?
                      _find(fieldsInError, { key: 'Nearest Cross Street' }).helperTextError
                      : ""
                  }
                  error={_some(fieldsInError, { key: 'Nearest Cross Street' })}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    var val = e.target.value;
                    if (validateNearest != null) {
                      clearTimeout(validateNearest)
                    }
                    validateNearest = setTimeout(() => {
                      if (getLength(val, 200)) {
                        setFieldsInError(_union(fieldsInError, [{ key: 'Nearest Cross Street', helperTextError: "Max 200 characters allowed" }]))
                      } else {
                        setFieldsInError(_reject(fieldsInError, { key: 'Nearest Cross Street' }))
                      }
                    }, 500)
                  }}
                  onBlur={(e) => {
                    setNearestCrossStreet(e.target.value)
                  }}
                />
                :
                <FormModalTextFieldReadOnly
                  type='text'
                  label='Nearest Cross Street'
                  value={nearestCrossStreet}
                />
              }
            </FieldWrapper>
            <FieldWrapper>
              {canEditShift ?
                <FormModalTextField
                  label='Suburb'
                  defaultValue={suburb}
                  helperText={
                    _some(fieldsInError, { key: 'Suburb' }) ?
                      _find(fieldsInError, { key: 'Suburb' }).helperTextError
                      : ""
                  }
                  error={_some(fieldsInError, { key: 'Suburb' })}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    var val = e.target.value;
                    if (validateSubrub != null) {
                      clearTimeout(validateSubrub)
                    }
                    validateSubrub = setTimeout(() => {
                      if (getLength(val, 50)) {
                        setFieldsInError(_union(fieldsInError, [{ key: 'Suburb', helperTextError: "Max 50 characters allowed" }]))
                      } else {
                        setFieldsInError(_reject(fieldsInError, { key: 'Suburb' }))
                      }
                    }, 500)
                  }}
                  onBlur={(e) => {
                    setSuburb(e.target.value)
                  }}
                />
                :
                <FormModalTextFieldReadOnly
                  type='text'
                  label='Suburb'
                  value={suburb}
                />
              }
            </FieldWrapper>
            <FieldWrapper>
              {canEditShift ?
                <FormModalDatePicker
                  label='Shift Date'
                  value={date}
                  onChange={(isoString: string) => {
                    setDate(isoString)
                  }}
                />
                :
                <FormModalTextFieldReadOnly
                  type='text'
                  label='Shift Date'
                  value={moment(date).format('MMM Do')}
                />
              }
            </FieldWrapper>
            <FieldWrapper>
              {canEditShift ?
                <FormModalSelectWrapper>
                  <FormModalSelect
                    type='create-shift-crews'
                    label='Crew'
                    value={crew}
                    onChange={(item: any) => {
                      setCrew(item.raw)
                    }}
                    options={CREW_OPTIONS}
                  />
                </FormModalSelectWrapper>
                :
                <FormModalTextFieldReadOnly
                  type='text'
                  label='Crew'
                  value={crew ? crew.name : null}
                />
              }
            </FieldWrapper>
            <FieldWrapper>
              <FormModalCheckboxGroup
                label={'Shift Type'}
                value={projectTypeIds}
                options={PROJECT_TYPE_OPTIONS}
                onChange={(item: any) => {
                  setProjectTypeIds(item)
                }}
                disabled={isEdit}
              />
            </FieldWrapper>
            <FieldWrapper>
              <FormModalRadioGroup
                label='Shift Time'
                value={shiftTypeId ? shiftTypeId : ''}
                disabled={isEdit && !canEditShift}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setShiftTypeId(e.target.value as ShiftTypeId)
                }}
                options={ShiftTypeOptions}
              />
            </FieldWrapper>
            <FieldWrapper>
              {canEditShift ?
                <FormModalTextField
                  label='KMs'
                  defaultValue={kms}
                  helperText={
                    _some(fieldsInError, { key: 'KMs' }) ?
                      _find(fieldsInError, { key: 'KMs' }).helperTextError
                      : ""
                  }
                  error={_some(fieldsInError, { key: 'KMs' })}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    var val = e.target.value;
                    if (validateSubrub != null) {
                      clearTimeout(validateSubrub)
                    }
                    validateSubrub = setTimeout(() => {
                      if (getLength(val, 50)) {
                        setFieldsInError(_union(fieldsInError, [{ key: 'KMs', helperTextError: "Max 10 characters allowed" }]))
                      } else {
                        setFieldsInError(_reject(fieldsInError, { key: 'KMs' }))
                      }
                    }, 500)
                  }}
                  onBlur={(e) => {
                    setKms(e.target.value)
                  }}
                />
                :
                <FormModalTextFieldReadOnly
                  type='text'
                  label='Kms'
                  value={kms}
                />
              }
            </FieldWrapper>
            <FieldWrapper style={{marginTop:'14px'}}>
              {  <CompThemeButton onClick={() => {
                     setSealDialogOpen(true);
                }}>
                   Sealing Requirements
                </CompThemeButton>
              }
            </FieldWrapper>
            <FieldWrapper style={{width:'260px'}}>
              {canEditShift ?
                <FormModalTextField
                  label='Apex Despatch/Sales Order#'
                  defaultValue={shiftSaleOrder}
                  helperText={
                    _some(fieldsInError, { key: 'Apex Despatch/Sales Order' }) ?
                      _find(fieldsInError, { key: 'Apex Despatch/Sales Order' }).helperTextError
                      : ""
                  }
                  error={_some(fieldsInError, { key: 'Apex Despatch/Sales Order' })}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    var val = e.target.value;
                    if (validateSubrub != null) {
                      clearTimeout(validateSubrub)
                    }
                    validateSubrub = setTimeout(() => {
                      if (getLength(val, 25)) {
                        setFieldsInError(_union(fieldsInError, [{ key: 'Apex Despatch/Sales Order', helperTextError: "Max 25 characters allowed" }]))
                      } else {
                        setFieldsInError(_reject(fieldsInError, { key: 'Apex Despatch/Sales Order' }))
                      }
                    }, 500)
                  }}
                  onBlur={(e) => {
                    setShiftSaleOrder(e.target.value)
                  }}
                />
                :
                <FormModalTextFieldReadOnly
                  type='text'
                  label='Sales Order'
                  value={shiftSaleOrder}
                />
              }
            </FieldWrapper>
			<FieldWrapper>
  				<FormModalCheckboxGroup
    				label={'Cost Only Shift'}
    				value={isCostOnlyShift ? ['Cost Only Shift'] : []}
    				options={[
      					{
        					label: 'Cost Only Shift',
        					value: 'Cost Only Shift',
      					}
    				]}
    				onChange={() => setIsCostOnlyShift(!isCostOnlyShift)}
    				disabled={false}
  			/>
			</FieldWrapper>
          </FieldsWrapper>
        </Section>
        {isEdit &&
          <Section>
            <Title>Shift Notes</Title>
            {loading && <SpinnerWrapper><Spinner size={30} /></SpinnerWrapper>}
            <DRSSectionComment
              shiftID={data!.id}
              onUpdated={handleOnUpdated}
            />
          </Section>
        }
        {isEdit && ["ASPHALT", "ASPHALT-SPRAY-SEAL"].includes(getProjectTypeId(projectTypeIds)) &&
          <Section>
            <Title>Asphalt Product Information</Title>
            {loading && <SpinnerWrapper><Spinner size={30} /></SpinnerWrapper>}
            <DRSSectionMaterialAspahltItems onUpdated={handleOnUpdated} />
            <DRSSectionMaterialAspahltOthers refreshTrigger={refreshTrigger} />
          </Section>
        }
        {isEdit && ["SPRAY-SEAL", "ASPHALT-SPRAY-SEAL"].includes(getProjectTypeId(projectTypeIds)) &&
          <Section>
            <Title>Spray Seal Product Information</Title>
            {loading && <SpinnerWrapper><Spinner size={30} /></SpinnerWrapper>}
            <DRSSectionMaterialSpraySealItems onUpdated={handleOnUpdated} />
            <DRSSectionMaterialSpraySealOthers refreshTrigger={refreshTrigger} />
          </Section>
        }

      </SectionWrapper>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClick}>
        Open ...
      </Button>
      <ShiftShortActionsMenu
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        onOpenDrs={() => {
          setDrsDialogOpen(true)
          setAnchorEl(null)
        }}
        onOpenCrewpack={() => {
          if (data !== null) {
            window.open(`#/crew-pack/${data.id}`, '_blank')
          }
          setAnchorEl(null)
        }}
      />
      {drsFormCard && drsDialogOpen && <DRSRedirectConfirmDialog
        data={drsFormCard.id}
        open={drsDialogOpen}
        onCancel={() => setDrsDialogOpen(false)}
        onClose={() => setDrsDialogOpen(false)}
      />
      }
      { sealDialogOpen && <SealRequirementDialog
        commentData={sealData}
        open={sealDialogOpen}
       
        onClose={() => setSealDialogOpen(false)}
        onSave={(str: any) => {
          console.log(str);
          setSealDialogOpen(false);setSealData(str);
        }}
      />
      }
      <Button onClick={(e: React.SyntheticEvent<any>) => {
        e.stopPropagation()
        resetDRS()
        onClose()
      }}>
        Cancel
      </Button>
      {canEditShift &&
        <Button
          color='primary'
          variant='contained'
          onClick={handleSave}
          disabled={fieldsInError.length > 0 ? true : false}
        >
          {isSaving && <React.Fragment><Spinner size={16} /> {`SAVING`} </React.Fragment>}
          {!isSaving && 'SAVE '}
        </Button>
      }
    </DialogActions>
  </Dialog>
}

const ModalTitle = styled.div`
  font-size: 24px;
`
const SectionWrapper = styled.div`
  /* display: flex; */
`
const Section = styled.div`
  /* padding: 0 30px;
  width: 270px; */
`
const Title = styled.div`
  font-size: 20px;
  color: #009688;
  margin: 24px 0;
  min-height: 28px;
`
const FieldsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const FieldWrapper = styled.div`
  padding: 0 1%;
  margin-bottom: 40px;
`
const FormModalSelectWrapper = styled.div`
  min-width: 200px;
`

const Spinner = styled(CircularProgress)`
  margin-right: 10px;
  position: relative;
`
const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`


export default CompShiftDialog