import React, { useRef, useState, useEffect, SyntheticEvent } from "react";
import styled, { css } from "styled-components";
import { observer } from "mobx-react-lite";
import IconButton from "@material-ui/core/IconButton";

import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import EventIcon from "@material-ui/icons/Event";
import { useCrewCalender } from "../StoreCrewCalender";
import WeekPicker from "./WeekPicker";
import useWindowSize from "@src/utils/hooks/useWindowSize";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import CircularProgress from "@material-ui/core/CircularProgress";
import BatchStartDateDialog from "../comps/BatchStartDateDialog";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { useAppStore } from "@src/AppStore";
import { Fullscreen, FullscreenExit } from "@material-ui/icons";
interface Props {
  handleNoteClick(): void;
  shiftNoteStyle: string;
}

const DateSwitch = ({ handleNoteClick, shiftNoteStyle }: Props) => {
  const ref = useRef(null);
  const innerWidth = useWindowSize().innerWidth;
  const [isBatchStartDateDialogOpen, setBatchStartDateDialogOpen] =
    useState<boolean>(false);
  const [isNoteSelected, setIsNoteSelected] = useState<string>("#000000");
  const {
    isWeekView,
    batchStartDay,
    goRefresh,
    gridNumber,
    setFullScreen,
    fullScreen,
    setBatchStartDay,
    setCurrentShiftCardViewFields,
    batchLength,
    setGridColsNumber,
    isCalendarLoading,
    isCalendarControlsDisabled,
    allUserShiftCardViews,
    webHookTime,
    setWebHookTime,webhookAnimationInterval
    
  } = useCrewCalender();
 // const webhookTimes = [{label:'30 secs',id:1,{label:'60 secs',id:2},{lable:'90 secs',id:3}];
  const { serialId } = useAppStore();
  const [selectedView, setSelectedView] = useState<any>(0);
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  const [isDefaultNotSelected, setIsDefaultNotSelected] = useState<boolean>(false);
  const [defaultWebhookTime, setDefaultWebhookTime] = useState<any>(webHookTime);
  useEffect(() => {
    if (shiftNoteStyle === "shfitNoteClassMin") {
      setIsNoteSelected("#009688");
    } else {
      setIsNoteSelected("#000000");
    }
  }, [shiftNoteStyle]);

  setTimeout(function () {
    if (allUserShiftCardViews && !isDefaultNotSelected) {
      const AllIndex = allUserShiftCardViews.findIndex(
        (item) => item.isDefault === true
      );
      if (AllIndex !== -1) {
        setSelectedView(allUserShiftCardViews[AllIndex].viewId);
      }
    }
  }, 1000);

  const handleFullScreen = () => {
    if (fullScreen) {
      document.getElementById("appBar").removeAttribute("style");
      document.getElementById("mainScreenTabs").removeAttribute("style");
      document.getElementById("exitFullScreenMode").style.display = "none";
      document.getElementById("mainScreenTabs").style.position =  'sticky';
      document.getElementById("mainScreenTabs").style.top = '0px';
      document.getElementById("mainScreenTabs").style.backgroundColor = 'white';
      document.getElementById("mainScreenTabs").style.zIndex = '1299';
      document.getElementById("mainScreenTabs").style.marginLeft ='-22px';
      document.getElementById("mainScreenTabs").style.padding =  '12px';
      document.getElementById("mainScreenTabs").style.width ='calc(100% + 44px)';
      document.getElementById('mainWrapperElement').style.top ='85px';
    } else {
      document.getElementById("appBar").style.display = "none";
      document.getElementById("mainScreenTabs").style.display = "none";
      document.getElementById("exitFullScreenMode").style.display = "block";
      document.getElementById('mainWrapperElement').style.top = '0px';
      
    }
    setFullScreen(!fullScreen);
  };
  const handleChangeView = (event: React.ChangeEvent<{ value: unknown }>) => {
    setIsDefaultNotSelected(true);
    //console.log(event.target.value);
    setIsFirstLoad(false);
    setSelectedView(event.target.value);
    const activeIndex = allUserShiftCardViews.findIndex(
      (data: any) => data.viewId === event.target.value
    );
    if (activeIndex !== -1) {
      setCurrentShiftCardViewFields(
        allUserShiftCardViews[activeIndex].selectedItems
      );
    }
  };
  const handleChangeWebhookTime = (event: React.ChangeEvent<{ value: unknown }>) => {
    setDefaultWebhookTime(event.target.value);
    setWebHookTime(event.target.value);
  
  };
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setGridColsNumber(event.target.value, innerWidth);
  };
  const handleNext = () => {
    setBatchStartDay(batchStartDay.clone().add(batchLength, "days"));
  };
  const handleBack = () => {
    setBatchStartDay(batchStartDay.clone().subtract(batchLength, "days"));
  };
  const handleClick = () => {
    if (shiftNoteStyle === "shfitNoteClass") {
      setIsNoteSelected("#009688");
      handleNoteClick();
      return;
    } else {
      setIsNoteSelected("#000000");
      handleNoteClick();
      return;
    }
  };
  const handleSelectDateOnClick = () => {
    setBatchStartDateDialogOpen(true);
  };
  const gridWidth: number = innerWidth / gridNumber;
  if (!isWeekView) return null;
  return (
    <Wrapper ref={ref}>
      <DateSwitching width={gridWidth}>
        <SpinnerWrapper>
          {isCalendarLoading && <Spinner size={18} />}
        </SpinnerWrapper>
        <FormModalSelectWrapper>
          <Select
            labelId="user-shift-card-views-select"
            id="user-shift-card-views-select"
            disabled={isCalendarControlsDisabled}
            value={selectedView}
            onChange={handleChangeView}
          >
            {allUserShiftCardViews.map((viewData: any) => {
              return (
                <MenuItem key={'menus_'+viewData.viewId} value={viewData.viewId}>
                  {viewData.viewName}&nbsp;
                </MenuItem>
              );
            })}
          </Select>
        </FormModalSelectWrapper>
       
        <FormModalSelectWrapperWebhook>
      
        <Tooltip title={`Depot Calendar Refresh Rate`} >
          <Select
            labelId="user-webhook-select"
            id="user-webhook-select"
            disabled={isCalendarControlsDisabled}
            value={defaultWebhookTime}
            onChange={handleChangeWebhookTime}
          >
            <MenuItem value={-1}>Pause</MenuItem>
          <MenuItem value={30000}>30 secs&nbsp;</MenuItem>
          <MenuItem value={60000}>60 secs</MenuItem>
          <MenuItem value={90000}>90 secs</MenuItem>
        
          </Select>
          </Tooltip>
        </FormModalSelectWrapperWebhook>
        {webhookAnimationInterval> 0  && <FormModalSelectWrapperAnimation>{webhookAnimationInterval}</FormModalSelectWrapperAnimation> }
        <ButtonWrapper>
          <Tooltip title={`Full Screen Mode`} placement="top">
            <IconButton
              id="fullScreenIcon"
              size="small"
              onClick={handleFullScreen}
              style={{ cursor: "pointer", color: "#009688" }}
            >
              {fullScreen ? <FullscreenExit /> : <Fullscreen />}
            </IconButton>
          </Tooltip>
        </ButtonWrapper>
        <ButtonWrapper>
          <Tooltip title={`Show Depot Notes`} placement="top">
            <IconButton
              id="noteIconMain"
              size="small"
              disabled={isCalendarControlsDisabled}
              onClick={handleClick}
              style={{ cursor: "pointer", color: isNoteSelected }}
            >
              <AssignmentIcon />
            </IconButton>
          </Tooltip>
        </ButtonWrapper>
        <ButtonWrapper>
          <Tooltip title={`Go to Date`} placement="top">
            <IconButton
              id="button-select-date"
              size="small"
              disabled={isCalendarControlsDisabled}
              onClick={handleSelectDateOnClick}
            >
              <EventIcon />
            </IconButton>
          </Tooltip>
        </ButtonWrapper>
        <Select
          labelId="week-select-dropdown-label"
          id="week-select-dropdown"
          disabled={isCalendarControlsDisabled}
          value={gridNumber}
          onChange={handleChange}
        >
          <MenuItem value={8}>1 week&nbsp;</MenuItem>
          <MenuItem value={15}>2 weeks</MenuItem>
          <MenuItem value={22}>3 weeks</MenuItem>
          <MenuItem value={29}>4 weeks</MenuItem>
        </Select>
        <StyledWeekPicker />
        <Tooltip title={`Previous ${batchLength} days`} placement="top">
          <IconButton
            id="button-previous-batch"
            size="small"
            disabled={isCalendarControlsDisabled}
            onClick={handleBack}
          >
            <KeyboardArrowLeft />
          </IconButton>
        </Tooltip>
        <Tooltip title={`Next ${batchLength} days`} placement="top">
          <IconButton
            id="button-next-batch"
            size="small"
            disabled={isCalendarControlsDisabled}
            onClick={handleNext}
          >
            <KeyboardArrowRight />
          </IconButton>
        </Tooltip>
      </DateSwitching>
      {isBatchStartDateDialogOpen && (
        <BatchStartDateDialog
          open={isBatchStartDateDialogOpen}
          onClose={() => setBatchStartDateDialogOpen(false)}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  /* display: flex; */
`;
const DateSwitching = styled.div<{ width: number }>`
  display: flex;
  justify-content: flex-end;
  padding: 12px 12px;
`;

const StyledWeekPicker = styled(WeekPicker)`
  margin-left: 15px;
  margin-right: 15px;
`;
const WidthLabel = styled(Typography)`
  color: #000000;
  font-size: 10px;
  padding-right: 5px;
` as typeof Typography;

const ButtonWrapper = styled.div`
  padding-top: 5px;
  padding-right: 15px;
`;
const IntervalAnim = styled.label`
  padding-right:2px;
  padding-left: 2px;
 
`;

const SpinnerWrapper = styled.div`
  padding-top: 10px;
  padding-right: 5px;
`;
const FormModalSelectWrapper = styled.div`
  min-width: 200px;
  margin-top: 5px;
`;
const FormModalSelectWrapperWebhook = styled.div`
min-width: 100px;
margin-top: 5px;
`;
const FormModalSelectWrapperAnimation = styled.div`
min-width: 30px;
    margin-top: 12px;
    font-size: 16px;
`;
const Spinner = styled(CircularProgress)`
  margin-right: 5px;
  position: relative;
`;

export default observer(DateSwitch);
