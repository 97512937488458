import React, { useState, useEffect, SyntheticEvent,useRef } from "react";
import styled, { css } from "styled-components";
import "./drsItem.css";
import { observer } from "mobx-react-lite";
import { useCrewCalender } from "../StoreCrewCalender";
import { useDrag,useDrop } from "react-dnd";
import _merge from "lodash/merge";
import _map from "lodash/map";
import _find from "lodash/find";
import _reject from "lodash/reject";
import { useSnackbar } from "@src/components/GlobalSnackbar";
import { apiShiftUpdate } from "@src/apis/shift-update";
import { Shift } from "@src/types/Shift";
import { useAppStore } from "@src/AppStore";
import { formatDRSStatus } from "@src/types/drs/DRSStatus";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import ShiftMoreActionsMenu from "./ActionMenu";
import { apiShiftRemove } from "@src/apis/shift-remove";
import DuplicateDialog from "./DuplicateDialog";
import { ShiftTypeId } from "@src/types/ShiftTypeId";
import { Crew } from "@src/types/Crew";
import { MaterialAsphaltProduct } from "@src/types/MaterialAsphaltProduct";
import { DRSMaterialSpraySealItem } from "@src/types/drs/material/MaterialSpraySealItem";
import { DragItemTypes } from "@src/types/DragItemTypes";
import useEditShiftRules from "@src/utils/hooks/useEditShiftRules";
import { getEmptyImage } from "react-dnd-html5-backend";
import moment from "moment";
import { useSelectedShifts } from "@src/utils/hooks/StoreSelectedShifts";
import DRSRedirectConfirmDialog from "./DRSRedirectConfirmDialog";
import CameraIcon from "@src/layout/Header/parts/CameraIcon";
import PDFIcon from "@src/layout/Header/parts/PDFIcon";
import DRSDuplicateDialog from "@src/modules/CrewPack/comps/DRSDuplicateDialog";
import {
  SRRTransportDto,
  SRRTransportsDetailsDto,
} from "@src/types/SRRTransport/SRRTransportDto";
import { Grid } from "@material-ui/core";
import { apiShiftRemoveMultiple } from "@src/apis/shift-remove-multiple";

interface Props {
  data: Shift;
  currentIndex: number;
  crewIndex: number;
  batchIndex: number;
  total: number;
  drsOpt: boolean;
  inDragLayer: boolean;
  className: string;
  onClick(e: SyntheticEvent): void;
  onPrevious(): void;
  onNext(): void;
  onEdit(shift: Shift): void;
  onCardOrder(type: any): void;
  onDuplicateDrs(data: any): void;
  moveCard(dragIndex: number,hoverIndex: number): void;
  id: string;
  onToggleShift(val: boolean): void,
  isMultiShift: boolean;
  isDuplicateOperation: string;

}

const DRSItem = ({
  data,
  currentIndex,
  crewIndex,
  batchIndex,
  total,
  inDragLayer,
  onClick,
  onPrevious,
  onNext,
  onEdit,
  onCardOrder,
  onDuplicateDrs,
  drsOpt,
  onToggleShift,
  className,
  moveCard,
  id,
  isMultiShift,
  isDuplicateOperation
}: Props) => {
  const ref: any = useRef();
  const isNightShift = data.plan.shiftType.id === ShiftTypeId.NIGHT;
  const asphaltProducts = data.products ? data.products.asphalt : [];
  const [isMovingCard, setIsMovingCard] = useState<boolean>(false);

  
  const reducedAsphaltProductsByPlantId = asphaltProducts.reduce<{
    [key: string]: MaterialAsphaltProduct[];
  }>((acc, curr) => {
    if (!curr.plant || !curr.plant.id) {
      return acc;
    }
    if (curr.plant.id in acc) {
      acc[curr.plant.id] = [...acc[curr.plant.id], curr];
      return acc;
    } else {
      acc[curr.plant.id] = [curr];
      return acc;
    }
  }, {});
  const plantsDisplayTexts = Object.keys(reducedAsphaltProductsByPlantId)
    .map((key) => reducedAsphaltProductsByPlantId[key][0].plant.name)
    .join();

  const tonnesDisplayTexts = Object.keys(reducedAsphaltProductsByPlantId)
    .map((key) =>
      reducedAsphaltProductsByPlantId[key].reduce(
        (acc, curr) => acc + (curr.tonnes ? curr.tonnes : 0),
        0
      )
    )
    .join();

  const spraySealProducts = data.products ? data.products.spraySeal : [];
  const reducedSpraySealProductsByPlantId = spraySealProducts.reduce<{
    [key: string]: DRSMaterialSpraySealItem[];
  }>((acc, curr) => {
    if (!curr.spraySealType || !curr.spraySealType.id) {
      return acc;
    }
    if (curr.spraySealType.id in acc) {
      acc[curr.spraySealType.id] = [...acc[curr.spraySealType.id], curr];
      return acc;
    } else {
      acc[curr.spraySealType.id] = [curr];
      return acc;
    }
  }, {});
  const sealTypeDisplayTexts = Object.keys(reducedSpraySealProductsByPlantId)
    .map(
      (key) =>
        reducedSpraySealProductsByPlantId[key][0].spraySealType.description
    )
    .join();
  const areaDisplayTexts = Object.keys(reducedSpraySealProductsByPlantId)
    .map((key) =>
      reducedSpraySealProductsByPlantId[key].reduce(
        (acc, curr) => acc + (curr.area ? curr.area : 0),
        0
      )
    )
    .join();
  const { serialId } = useAppStore();
  const { alert, alertError } = useSnackbar();
  const {
    currentBatch,
    selectedStart,
    selectedEnd,
    crews,
    storeCrewData,
    startCrewIndex,
    endCrewIndex,
    dragStartDate,
    setDragStartDate,
    updateSelectedStartBox,
    goRefresh,
    drss,
    setDrss,
    isCalendarControlsDisabled,
    setIsCalendarLoading,
    currentShiftCardView,
    setCalendarControlsDisabled,
  } = useCrewCalender();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isDuplicateDialogOpen, setDuplicateDialogOpen] =
    useState<boolean>(false);
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [shiftCards, setShiftCards] = useState<any>([]);
  const [date, setDate] = useState<string>("");
  const [canDragShift, setCanDragShift] = useState<boolean>(true);
  const [drsDialogOpen, setDrsDialogOpen] = useState<boolean>(false);
  const [drsDuplicateDialogOpen, setDrsDuplicateDialogOpen] =
    useState<boolean>(false);
  const [canShiftDateBeChanged, canShiftCrewBeChanged, canShiftCrewBeDeleted] =
    useEditShiftRules(data);
  const {
    selectedShiftCount,
    selectedShifts,
    addSelectedShift,
    removeSelectedShift,
    clearSelectedShifts,
    drsStatusCrewsCantBeChanged,
    isDragHappening,
    setIsDragHappening,
  } = useSelectedShifts();

  useEffect(() => {
    let found = selectedShifts.find((o: any) => o.id === data.id);
    if (found) setIsSelected(true);
    else setIsSelected(false);
  }, [selectedShiftCount, currentIndex]);
  useEffect(() => {
    if (currentBatch[selectedEnd])
      setDate(currentBatch[selectedEnd].toISOString());
  }, [selectedEnd]);

  useEffect(() => {
    if (data !== null) {
      setCanDragShift(canShiftDateBeChanged);
      setShiftCards(getShiftCards());

      //console.log(`DRSItem useEffect() data=${JSON.stringify(data, null, 2)}`)
    }
  }, [data]);
  function renderMixTypeTonnes() {
    return <MixTypeTonnesElement>
      {plantsDisplayTexts ? (
          renderPlant()
        ) : (
          <BlankWrapper></BlankWrapper>
        )}
        {areaDisplayTexts ? (
          <ShiftInfoRow
            isNight={isNightShift ? 1 : 0}
            title={`area: ${areaDisplayTexts}`}
          >
            {renderAreas() }
          </ShiftInfoRow>
        ) : 
          <BlankWrapper></BlankWrapper>}
          {sealTypeDisplayTexts ? (
              <ShiftInfoRow
                isNight={isNightShift ? 1 : 0}
                title={sealTypeDisplayTexts}
              >
                {renderSealType()}
              </ShiftInfoRow>
            ) : null}
    </MixTypeTonnesElement>
  }
  function getShiftCards() {
    return [
      {
        label: "Project Number",
        value: (
          <ProjectNumELement colorCode={data.projectColorCode ? ((data.projectColorCode == '#002A41' || data.projectColorCode == '002A41') ? 'transparent' : data.projectColorCode) : 'transparent' } isNight={isNightShift ? 1 : 0}>{`${data.projectId || data.projectInternalId
            }`}</ProjectNumELement>
        ),
      },
      {
        label: "Customer",
        value: data.customerName ? (
          <ShiftInfoRow
            isNight={isNightShift ? 1 : 0}
            title={data.customerName}
          >
            {data.customerName}
          </ShiftInfoRow>
        ) : (
          <BlankWrapper></BlankWrapper>
        ),
      },
      {
        label: "Street",
        value: data.plan.street ? (
          <ShiftInfoRow
            isNight={isNightShift ? 1 : 0}
            title={`street: ${data.plan.street}`}
          >
            {data.plan.street}
          </ShiftInfoRow>
        ) : (
          <BlankWrapper></BlankWrapper>
        ),
      },
      {
        label: "Suburb",
        value: data.plan.suburb ? (
          <ShiftInfoRow
            isNight={isNightShift ? 1 : 0}
            title={`suburb: ${data.plan.suburb}`}
          >
            {data.plan.suburb}
          </ShiftInfoRow>
        ) : (
          <BlankWrapper></BlankWrapper>
        ),
      },
      {
        label: "Mix Type & Tonnes",
        value: renderMixTypeTonnes()
      },
      {
        label: "Shuttle Buggy",
        value: data.MREShuttleBuggy ? (
          <ShiftInfoRow
            isNight={isNightShift ? 1 : 0}
            title={`${data.MREShuttleBuggy}`}
          >
            {data.MREShuttleBuggy}
          </ShiftInfoRow>
        ) : (
          <BlankWrapper></BlankWrapper>
        ),
      },
      {
        label: "Transport Details",
        value: returnSRRTransportData(
          data.SRRTransport,
          data.srrtransportsdetails
        ) ? (
          <ShiftTransportInfoRow
            isNight={isNightShift ? 1 : 0}
            title={`${returnSRRTransportData(
              data.SRRTransport,
              data.srrtransportsdetails
            )}`}
          >
            {returnSRRTransportData(
              data.SRRTransport,
              data.srrtransportsdetails
            )}

            {renderTransportInfoRequest()}

          </ShiftTransportInfoRow>
        ) : (
          <BlankWrapper></BlankWrapper>
        ),
      },
      {
        label: "Sealing Requirements",
        value: data.ShiftSealingRequirment ? (
          <ShiftInfoRow
            isNight={isNightShift ? 1 : 0}
            title={`${data.ShiftSealingRequirment}`}
          >
            {data.ShiftSealingRequirment}
          </ShiftInfoRow>
        ) : (
          <BlankWrapper></BlankWrapper>
        ),
      },
      {
        label: "APEX Dispatch Order / Sales Order",
        value: data.ShiftSalesOrder ? (
          <ShiftInfoRow
            isNight={isNightShift ? 1 : 0}
            title={`${data.ShiftSalesOrder}`}
          >
            {data.ShiftSalesOrder}
          </ShiftInfoRow>
        ) : (
          <BlankWrapper></BlankWrapper>
        ),
      },
      {
        label: "Default PM",
        value: data.DefaultProjectManager ? (
          <ShiftInfoRow
            isNight={isNightShift ? 1 : 0}
            title={`${data.DefaultProjectManager}`}
          >
            {data.DefaultProjectManager}
          </ShiftInfoRow>
        ) : (
          <BlankWrapper></BlankWrapper>
        ),
      },
      {
        label: "Supervisor",
        value: data.isSupervisor ? (
          <ShiftInfoRow
            isNight={isNightShift ? 1 : 0}
            title={`${data.isSupervisor}`}
          >
            {data.isSupervisor}
          </ShiftInfoRow>
        ) : (
          <BlankWrapper></BlankWrapper>
        ),
      },
      {
        label: "Compaction Testing / Asphalt Test",
        value:
          data.PLNCompactionTesting && data.PLNCompactionTesting != "," ? (
            <ShiftInfoRow
              isNight={isNightShift ? 1 : 0}
              title={`Compaction Testing / Asphalt Test`}
            >
              {data.PLNCompactionTesting}
            </ShiftInfoRow>
          ) : (
            <BlankWrapper></BlankWrapper>
          ),
      },
      {
        label: "TC Comments",
        value: data.TCSpecialReq ? (
          <ShiftInfoRow isNight={isNightShift ? 1 : 0} title={`TC Comments`}>
            {data.TCSpecialReq}
          </ShiftInfoRow>
        ) : (
          <BlankWrapper></BlankWrapper>
        ),
      },
      {
        label: "Mix Type (DRS)",
        value: data.drsProjectType ? (
          <ShiftInfoRow isNight={isNightShift ? 1 : 0} title={`Mix Type (DRS)`}>
            {data.drsProjectType.id}
          </ShiftInfoRow>
        ) : (
          <BlankWrapper></BlankWrapper>
        ),
      },
      {
        label: "Shift Type",
        value: (
          <ShiftInfoRow isNight={isNightShift ? 1 : 0} title={`Shift Type)`}>
            {isNightShift ? "Night Shift" : "Day Shift"}
          </ShiftInfoRow>
        ),
      },
      {
        label: "Labour Accommodation", 
        value: data.PLN_Accommodation ? (
          <ShiftInfoRow isNight={isNightShift ? 1 : 0} title={`Labour Accommodation`}>
            {'Labour Accommodation: '+data.PLN_Accommodation }
          </ShiftInfoRow>
        ) : (
          <BlankWrapper></BlankWrapper>
        ),
      },
    ];
  }

  const [{ isDragging }, drag, preview] = useDrag({
    item: {
      type: DragItemTypes.DRSItem,
      shift: data,
      index: currentIndex
    },
    canDrag: canDragShift,
    begin: (monitor) => {
      if (!isCalendarControlsDisabled) {
        setIsDragHappening(true);
        setDragStartDate(data.date);
        updateSelectedStartBox(batchIndex, crewIndex);
        // add selected shift when not selecting multiple shifts
        addSelectedShift(data);
      } else {
        alert({
          type: "error",
          message: `Shifts can't be dragged while the Calendar is updating.`,
        });
      }
    },
    end: async (item: any, monitor) => {
      //console.log(`DRSItem useDrag() end`)
      if (!isCalendarControlsDisabled) {
        let offset: number,
          options: any = {};
        setIsDragHappening(false);
        const dropResult = monitor.getDropResult();
        const crew: Crew = storeCrewData[endCrewIndex];

        if (
          startCrewIndex != endCrewIndex &&
          drsStatusCrewsCantBeChanged.length > 0
        ) {
          alert({
            type: "error",
            message: `Shift Crew can not be changed when status is${drsStatusCrewsCantBeChanged}.`,
          });
          clearSelectedShifts();
          return;
        }

        // if dragging Shift has been dropped on a different date or Crew
        if (
          item &&
          dropResult &&
          (selectedStart != selectedEnd || startCrewIndex != endCrewIndex)
        ) {
          const shiftIds: Array<string> = selectedShifts.map((shift: Shift) => {
            return ` #${shift.id}`;
          });
          const draggingStartDate = moment(dragStartDate).startOf("day");
          const draggingEndDate = moment(date).startOf("day");
          offset = draggingEndDate.diff(draggingStartDate, "days");

          try {
            // Update the store so Shift doesn't flick back to it's orginal date
            const selectedShiftIds: number[] = _map(selectedShifts, "id");
            let newDrss: any[] = _map(drss, function (drs) {
              if (selectedShiftIds.includes(drs.id)) {
                let selectedShift: any = _find(selectedShifts, ["id", drs.id]);
                return _merge({}, selectedShift, {
                  date: moment(selectedShift.date).add(offset, "days"),
                  plan: { crew: crew },
                });
              } else {
                return drs;
              }
            });

            setDrss(newDrss);
            setIsCalendarLoading(true);
            setCalendarControlsDisabled(true);

            const uploadResult = await Promise.all(
              selectedShifts.map((shift: Shift) => {
                let newShiftDate = moment(shift.date).add(offset, "days");

                options = {
                  shiftId: shift.id,
                  description: shift.description,
                  project: {
                    projectInternalId: shift.projectInternalId,
                    id: shift.projectId,
                    name: shift.projectName,
					planKMPlantToDepot: shift.plan.kmPlantToDepot
                  },
                  shiftTypeId: shift.plan.shiftType.id,
                  date: newShiftDate.utc().format(),
                  crewId: crew.id,
                  drsProjectTypeId: shift.drsProjectType.id,
                  serialId,
                  street: shift.plan.street,
                  nearestCrossStreet: shift.plan.nearestCrossStreet,
                  suburb: shift.plan.suburb,
				  IsCostOnlyShift: shift.IsCostOnlyShift,
				  ShiftSalesOrder: shift.ShiftSalesOrder,
				  ShiftSealingRequirment: shift.ShiftSealingRequirment
                };

                try {
                  return apiShiftUpdate(options);
                } catch (e) {
                  setIsCalendarLoading(false);
                  setCalendarControlsDisabled(false);
                  console.log("DRSItem apiShiftUpdate() Error:", e);
                }
              })
            );
            let message = `Shift${shiftIds.length > 1 ? "s" : ""} ${shiftIds}`;

            if (selectedStart != selectedEnd) {
              if (shiftIds.length > 1) {
                message += ` dates have been changed`;
              } else {
                message += ` date changed to ${currentBatch[selectedEnd].format(
                  "DD-MM-YY"
                )}`;
              }
            }

            if (startCrewIndex != endCrewIndex)
              message += ` crew changed to ${crew.name}`;

            alert({ message });
            goRefresh();
          } catch (e) {
            //console.log('DRSItem apiShiftUpdate() Error:', e);
            alertError();
          }
        }
        clearSelectedShifts();
      }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const returnSRRTransportData = (
    srrTransportDto: SRRTransportDto,
    srrTransportsDetailsDto: SRRTransportsDetailsDto
  ) => {
    let deliveryString = [];
    let profileString = [];
    // let deliveryTransport = ;
    if (srrTransportDto && srrTransportDto.details) {
      if (srrTransportDto && srrTransportDto.details) {
        for (let i = 0; i < srrTransportDto.details.length; i++) {
          const currItem = srrTransportDto.details[i];
          // console.log(`currItem`);
          // console.log(JSON.stringify(currItem));
          // console.log(`currItem`);
          if (currItem.transportType.id === "Delivery") {

            // deliveryString = deliveryString + " Delivery ";
            // deliveryString =
            //   deliveryString +
            deliveryString.push(
              <TransportItem>{returnSrrTransportDetails(
                currItem.transportType.id,
                srrTransportsDetailsDto
              )}</TransportItem>);
            deliveryString.push(<TransportItem>{'Tonnes: ' + currItem.orderedTonnesPerRound}</TransportItem>)
          } else {
            // profileString = profileString + " Profiling ";
            // profileString =
            //   profileString +
            profileString.push(<TransportItem>{returnSrrTransportDetails(
              currItem.transportType.id,
              srrTransportsDetailsDto
            )}</TransportItem>);
            profileString.push(<TransportItem>{'Tonnes: ' + currItem.orderedTonnesPerRound}</TransportItem>)
          }
        }

        return <TransportArea>
          {deliveryString.length > 0 && <TransportDelivery>
            <TransportHeader>
              Delivery
            </TransportHeader>
            {deliveryString}
          </TransportDelivery>}
          {profileString.length > 0 && <TransportProfiling>
            <TransportHeader>
              Profiling
            </TransportHeader>
            {profileString}
          </TransportProfiling>}
        </TransportArea>
        // profileString + "\n\n" + deliveryString;
      }
    }
  };

  const returnSrrTransportDetails = (
    isDelivery: string,
    srrTransportsDetailsDto: SRRTransportsDetailsDto
  ) => {
    let returnValue = [];
    if (srrTransportsDetailsDto && srrTransportsDetailsDto.details) {
      for (let i = 0; i < srrTransportsDetailsDto.details.length; i++) {
        let item = srrTransportsDetailsDto.details[i];
        // console.log(`item`);
        // console.log(JSON.stringify(item));
        // console.log(`item`);
        if (item.transportTypeId === isDelivery && item.specValue) {
          // if (returnValue.length > 0) returnValue += " - ";
          returnValue.push(<TransportItem>{item.specId}</TransportItem>);
        }
      }
      return returnValue;
    } else {
      return returnValue;
    }
  };

  const handleMoreClicked = (e: React.SyntheticEvent<any>) => {
    e.stopPropagation();
    setAnchorEl(e.target as HTMLElement);
  };
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    //console.log(`DRSItem handleClick() e.ctrlKey=${e.ctrlKey}`)
    if (e.ctrlKey || e.altKey || e.metaKey) {
      if (isSelected) {
        removeSelectedShift(data);
        return;
      }
      if (!canShiftDateBeChanged) {
        alert({
          type: "error",
          message: `Shift date can't be changed when status is ${formatDRSStatus(
            data.status.id
          )}.`,
        });
        return;
      }
      if (isCalendarControlsDisabled) {
        alert({
          type: "error",
          message: `Shifts can't be dragged while the Calendar is updating.`,
        });
        return;
      }
      if (
        selectedShifts.length > 0 &&
        data.plan.crew.id !== selectedShifts[0].plan.crew.id
      ) {
        alert({
          type: "error",
          message: `Only Shifts for the same Crew can be dragged together.`,
        });
      } else {
        addSelectedShift(data);
      }
      return;
    }
    clearSelectedShifts();
    onClick(e);
  };

  const handleDeleteMultipleAPI = async (id: any) => {
    apiShiftRemove({
      shiftId: id,
      serialId,
    })
      .then(() => {
      const newDrss: any[] = _reject(drss, { id: id });
      setDrss(newDrss);
      goRefresh();
      alert({
        message: `Shift #${id} was deleted.`,
      });
      })
      .catch((error) => {
        console.log('-----');
        console.log(error.response.data);
        console.log('-----');
      setIsCalendarLoading(false);
      setCalendarControlsDisabled(false);
      let errorMessage = "Unable to delete shift due to completed documents. Please contact the system admin. Error: ";
      if (error && error.response) {
        if(error.response.data) {
          if(error.response.data.message){
            errorMessage += error.response.data.message;
          }
         
        }
       
      } else {
        errorMessage += "An error occurred. Please try again later.";
      }
      alert({ message: errorMessage, type: "error", autoHide: true });
      });
  }
  const handleDeleteMultiple = async () => {
    if (!(data && !canShiftCrewBeDeleted)) {
      alert({
        type: "error",
        message: `Shifts cannot be removed when status not in draft.`,
      });
      return;
    }
	setIsCalendarLoading(true);
	setCalendarControlsDisabled(true);
   var shiftIds = [];
  for(var i = 0;i<selectedShifts.length;i++) {
    // await shiftIds.push(selectedShifts[i].id)
     await handleDeleteMultipleAPI(selectedShifts[i].id)
  }
  console.log(`<<<<<<<<<----------selectedShifts--->>>>>>>>`);
  console.log(shiftIds);
  console.log(`<<<<<<<<<----------selectedShifts--->>>>>>>>`);
	// apiShiftRemoveMultiple({
	//   shiftIds: shiftIds,
	//   serialId,
	// })
	//   .then(() => {
	// 	const newDrss: any[] = _reject(drss, { id: data.id });
	// 	setDrss(newDrss);
	// 	goRefresh();
	// 	alert({
	// 	  message: `Shifts #${shiftIds.join(',')} was deleted.`,
	// 	});
	//   })
	//   .catch((error) => {
  //     console.log('-----');
  //     console.log(error.response.data);
  //     console.log('-----');
	// 	setIsCalendarLoading(false);
	// 	setCalendarControlsDisabled(false);
	// 	let errorMessage = "Unable to delete shift due to completed documents. Please contact the system admin. Error: ";
	// 	if (error && error.response) {
  //     if(error.response.data) {
  //       if(error.response.data.message){
  //         errorMessage += error.response.data.message;
  //       }
       
  //     }
		 
	// 	} else {
	// 	  errorMessage += "An error occurred. Please try again later.";
	// 	}
	// 	alert({ message: errorMessage, type: "error", autoHide: true });
	//   });
	
  };
  const handleDelete = async () => {
    if (!(data && !canShiftCrewBeDeleted)) {
      alert({
        type: "error",
        message: `Shift #${data.id
          } cannot be removed when status is (${formatDRSStatus(
            data.status.id
          )}).`,
      });
      return;
    }
	setIsCalendarLoading(true);
	setCalendarControlsDisabled(true);
	apiShiftRemove({
	  shiftId: data.id,
	  serialId,
	})
	  .then(() => {
		const newDrss: any[] = _reject(drss, { id: data.id });
		setDrss(newDrss);
		goRefresh();
		alert({
		  message: `Shift #${data.id} was deleted.`,
		});
	  })
	  .catch((error) => {
      console.log('-----');
      console.log(error.response.data);
      console.log('-----');
		setIsCalendarLoading(false);
		setCalendarControlsDisabled(false);
		let errorMessage = "Unable to delete shift due to completed documents. Please contact the system admin. Error: ";
		if (error && error.response) {
      if(error.response.data) {
        if(error.response.data.message){
          errorMessage += error.response.data.message;
        }
       
      }
		 
		} else {
		  errorMessage += "An error occurred. Please try again later.";
		}
		alert({ message: errorMessage, type: "error", autoHide: true });
	  });
	
  };
  const handleDuplicate = () => {
    setDuplicateDialogOpen(true);
  };
  // const getStoreData = () => {
  //  let storeData= [];

  //  if(JSON.stringify(DefaultShiftlocalStoreData)) {

  //   return DefaultShiftlocalStoreData;
  //  }else{
  //  return  storeData
  //  }
  // }
  const getTimeFormat = (timeData: any) => {
    const timeArr = timeData.split(':');
    if (timeArr.length > 0) {
      return timeArr[0] + ':' + timeArr[1];
    }
  }
  const handleDuplicateDrs = (copyData: any) => {
    const drsSection: any = [];
    if (copyData.labour) drsSection.push("labour");
    if (copyData.mobileAssets) drsSection.push("mobileassets");
    if (copyData.material) drsSection.push("material");
    if (copyData.subcontractor) drsSection.push("subcontractor");
    if (copyData.qa) drsSection.push("qa");
    onDuplicateDrs({ drsSection, shift: data });

    setDrsDuplicateDialogOpen(false);
  };
  const handleShiftOrder = (type: any) => {
    onCardOrder(type);
  };
  const handlePrevious = (e: React.SyntheticEvent<any>) => {
    e.stopPropagation();
    onPrevious();
  };
  const handleNext = (e: React.SyntheticEvent<any>) => {
    e.stopPropagation();
    onNext();
  };
  const handleEdit = () => {
    onEdit(data);
  };
  const getLocaleDigitNumber = (val: any)=>{
return val.toLocaleString('en-AU');
  }
  const renderPlant = () => {
    let arrPlant = [];
    if (asphaltProducts.length > 0) {
      arrPlant = Object.keys(reducedAsphaltProductsByPlantId).map(
        (res: any) => {
          const DataObj = reducedAsphaltProductsByPlantId[res].reduce<{
            [key: string]: any[];
          }>((acc: any, curr: any) => {
            if (!curr.product || !curr.product.id) {
              return acc;
            }
            if (curr.product.id in acc) {
              acc[curr.product.id].tonn =
                acc[curr.product.id].tonn + curr.tonnes;

              return acc;
            } else {
              acc[curr.product.id] = {
                desc: curr.product.description,
                tonn: curr.tonnes,
                colour: curr.plant.colourCode,
                name: curr.plant.name,
              };
              return acc;
            }
          }, {});
          return reducedAsphaltProductsByPlantId[res].length > 0 ? (
            <PlantBlock key={'plant_'+reducedAsphaltProductsByPlantId[res][0].plant.name}
              backcolor={
                reducedAsphaltProductsByPlantId[res][0].plant.colourCode
              }
            >
              <PlantLabel>
                {reducedAsphaltProductsByPlantId[res][0].plant.name}
              </PlantLabel>
              {renderPlantHTML(DataObj)}
            </PlantBlock>
          ) : null;
        }
      );

      return arrPlant;
    } else return plantsDisplayTexts;
  };
  const renderPlantHTML = (DataObj: any) => {
    return Object.keys(DataObj).map((res: any,indxPlan) => {
      return (
        <div key={'indPlan'+indxPlan}>
          <PlantLabel>{DataObj[res].desc}</PlantLabel>
          <PlantLabel>{DataObj[res].tonn}</PlantLabel>
        </div>
      );
    });
  };
  const renderLabourMaterials = () => { 
    let   spraySealProductsData = data.products.spraySeal;
     const gridItems =  spraySealProductsData.map((spData,index)=>{
      return   checkLabourMaterialOptions() ?  <Grid container className={index%2 ? 'even' : index%3 ? 'third' : 'odd'}  spacing={1}>
                  <Grid xs={8}>
                    {currentShiftCardView.indexOf("Type of Seal")!=-1 && spData.spraySealType && <GridInfoRowItem >{spData.spraySealType.description ? spData.spraySealType.description : '-'}</GridInfoRowItem> }
                    {currentShiftCardView.indexOf("Spray Binder Type")!=-1 && spData.binderType &&  <GridInfoRowItem >{spData.binderType.description ? spData.binderType.description : '-'}</GridInfoRowItem>}
                    {currentShiftCardView.indexOf("Aggregate Size")!=-1 && spData.aggregateSize &&  <GridInfoRowItem >{spData.aggregateSize.description ? spData.aggregateSize.description : '-'}</GridInfoRowItem>}
                    {currentShiftCardView.indexOf("Application rate ltsm2")!=-1 && spData.orderedApplicationRate && <GridInfoRowItem >{spData.orderedApplicationRate ? (getLocaleDigitNumber(spData.orderedApplicationRate)+' (L/m') : '- (L/m' }<sup>2</sup>)</GridInfoRowItem>}
                    {currentShiftCardView.indexOf("Ordered Rate (m3/m2)")!=-1 && <GridInfoRowItem>{spData.orderedRate ? getLocaleDigitNumber(spData.orderedRate) : '-'} (m<sup>3</sup>/m<sup>2</sup>)</GridInfoRowItem>}
                    
                    </Grid>
                  <Grid xs={4}>
                   {currentShiftCardView.indexOf("Area (m2)")!=-1 && <GridInfoRowItem >{spData.area ? getLocaleDigitNumber(spData.area) : '-'}m<sup>2</sup></GridInfoRowItem>}
                    {currentShiftCardView.indexOf("Residual Binder (L)")!=-1 && <GridInfoRowItem >{spData.residualBinder ? getLocaleDigitNumber(spData.residualBinder) : '-'} (L)</GridInfoRowItem>}
                    {currentShiftCardView.indexOf("Aggregate Tonnes")!=-1 && <GridInfoRowItem >{spData.aggregateRequired_Tonnes ? getLocaleDigitNumber(spData.aggregateRequired_Tonnes) : '-'} (T)</GridInfoRowItem>}
                   
                  </Grid>
               </Grid> : <BlankWrapper></BlankWrapper>
        });
        return gridItems;
  
  }
  const checkLabourMaterialOptions = () => {
    if(currentShiftCardView.indexOf("Type of Seal")!=-1 || 
    currentShiftCardView.indexOf("Spray Binder Type")!=-1 ||
    currentShiftCardView.indexOf("Aggregate Size")!=-1 ||
    currentShiftCardView.indexOf("Application rate ltsm2")!=-1 || 
    currentShiftCardView.indexOf("Ordered Rate (m3/m2)")!=-1 ||
    currentShiftCardView.indexOf("Area (m2)")!=-1 ||
    currentShiftCardView.indexOf("Residual Binder (L)")!=-1 ||
    currentShiftCardView.indexOf("Aggregate Tonnes")!=-1
    )
    return true;
    else
      return false;
  }
  
  const renderTransportInfoRequest = () => {
    return <TransportArea>  
      {data.srrtransportadditionalinformation.startTime && <ShiftInfoRow isNight={isNightShift ? 1 : 0}>{'Start Time: ' + getTimeFormat(data.srrtransportadditionalinformation.startTime)}</ShiftInfoRow>}
      {data.srrtransportadditionalinformation.lunchTime && <ShiftInfoRow isNight={isNightShift ? 1 : 0}>{'Lunch Time: ' + getTimeFormat(data.srrtransportadditionalinformation.lunchTime)}</ShiftInfoRow>}
      {data.srrtransportadditionalinformation.lastTruckTime && <ShiftInfoRow isNight={isNightShift ? 1 : 0}>{'Last Truck Onsite: ' + getTimeFormat(data.srrtransportadditionalinformation.lastTruckTime)}</ShiftInfoRow>}
      {data.srrtransportadditionalinformation.preLunchRate && <ShiftInfoRowItemDLVItem isNight={isNightShift ? 1 : 0}>{'DLV Rate before Lunch (tonnes / hr): ' + data.srrtransportadditionalinformation.preLunchRate}</ShiftInfoRowItemDLVItem>}
      {data.srrtransportadditionalinformation.postLunchRate && <ShiftInfoRowItemDLVItem isNight={isNightShift ? 1 : 0}>{'DLV Rate after Lunch (tonnes / hr): ' + data.srrtransportadditionalinformation.postLunchRate}</ShiftInfoRowItemDLVItem>}
    </TransportArea>
  }
  const renderSealType = () => {
    if (spraySealProducts.length <= 1) {
      return sealTypeDisplayTexts;
    }
    if (!sealTypeDisplayTexts) {
      return "";
    }
    return `* ${sealTypeDisplayTexts}`;
  };
  const renderAreas = () => {
    if (spraySealProducts.length <= 1) {
      return areaDisplayTexts;
    }
    if (!areaDisplayTexts) {
      return "";
    }
    return `* ${areaDisplayTexts}`;
  };

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);
  function renderShiftRow(viewData: any) {
    const foundIndex = shiftCards.findIndex((o: any) => o.label === viewData);
    return foundIndex != -1 ? (
      shiftCards[foundIndex].value
    ) : (
      <BlankWrapper></BlankWrapper>
    );
  }
  const [{ handlerId }, drop] = useDrop({
    accept: DragItemTypes.DRSItem,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover:(item: any, monitor) =>{
      if (!ref.current) {
        return;
      }
    },
    drop:(item: any, monitor) =>{
      if (!ref.current) {
        return;
      }
    
      const dragIndex = currentIndex;
      const hoverIndex = item.index;
      console.log('--------=-');
       console.log(dragIndex);
       console.log(hoverIndex);
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
       
      setIsMovingCard(true);
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });

  drag(drop(ref));
  return (
    <Wrapper className={className}
      ref={ref}
      isSelected={isSelected}
      inDragLayer={inDragLayer}
      isDragHappening={isDragHappening}
      data-handleriid={handlerId}
      id={id}
    >
      {data.crewPackNumberOfPdfs != "0" && (
        <PDFIcon
          isNight={isNightShift}
          link={"/crew-pack/" + data.id + "#documents"}
          left={
            total !== 1
              ? data.crewPackNumberOfPhotos != "0"
                ? "114px"
                : "85px"
              : data.crewPackNumberOfPhotos != "0"
                ? "70px"
                : "41px"
          }
        />
      )}
      {data.crewPackNumberOfPhotos != "0" && (
        <CameraIcon
          isNight={isNightShift}
          link={"/crew-pack/" + data.id}
          left={total !== 1 ? "85px" : "41px"}
        />
      )}
      <ShiftBlock  id={'shiftblock'+id} className={isDuplicateOperation}
        onClick={handleClick}
        isNight={isNightShift ? 1 : 0}
        isSelected={isSelected}
      >
        <NavIcons>
          {!drsOpt && (
            <MoreButton
              isnight={isNightShift ? 1 : 0}
              size="small"
              onClick={handleMoreClicked}
            >
              <MoreVertIcon
                fontSize="small"
                style={{ padding: 0, height: 14, width: 14 }}
              />
            </MoreButton>
          )}
         
          <ShiftNo>
            {`${currentIndex + 1}/${total}`}
            {total !== 1 && !drsOpt && (
              <React.Fragment>
                <NavIconButton
                  isNight={isNightShift ? 1 : 0}
                  disabled={total === 1}
                  size="small"
                  onClick={handlePrevious}
                >
                  <KeyboardArrowLeft
                    fontSize="small"
                    style={{ padding: 0, height: 14, width: 14 }}
                  />
                </NavIconButton>
                <NavIconButton
                  isNight={isNightShift ? 1 : 0}
                  disabled={total === 1}
                  size="small"
                  onClick={handleNext}
                >
                  <KeyboardArrowRight
                    fontSize="small"
                    style={{ padding: 0, height: 14, width: 14 }}
                  />
                </NavIconButton>
              </React.Fragment>
            )}
          </ShiftNo>
        </NavIcons>
        {currentShiftCardView.length == 0 && (
          <ShiftCard isStoreData={true}>
            {
              <ProjectNumELement isNight={isNightShift ? 1 : 0} colorCode={data.projectColorCode ?  ((data.projectColorCode == '#002A41' || data.projectColorCode == '002A41') ? 'transparent' : data.projectColorCode)  : 'transparent' } >{`${data.projectId || data.projectInternalId
                }`}</ProjectNumELement>
            }
            {
              <ShiftInfoRow
                isNight={isNightShift ? 1 : 0}
                title={data.customerName}
              >
                {data.customerName}
              </ShiftInfoRow>
            }
            {
              <ShiftInfoRow
                isNight={isNightShift ? 1 : 0}
                title={`street: ${data.plan.street}`}
              >
                {data.plan.street}
              </ShiftInfoRow>
            }
            <ShiftInfoRow
              isNight={isNightShift ? 1 : 0}
              title={`suburb: ${data.plan.suburb}`}
            >
              {data.plan.suburb}
            </ShiftInfoRow>
            {plantsDisplayTexts ? renderPlant(): null}
            {/* { <ShiftInfoRow isNight={isNightShift ? 1 : 0} title={`tonnes: ${tonnesDisplayTexts}`}>{renderTonnes()}</ShiftInfoRow>} */}
            {sealTypeDisplayTexts ? (
              <ShiftInfoRow
                isNight={isNightShift ? 1 : 0}
                title={sealTypeDisplayTexts}
              >
                {renderSealType()}
              </ShiftInfoRow>
            ) : null}
            {areaDisplayTexts ? (
              <ShiftInfoRow
                isNight={isNightShift ? 1 : 0}
                title={`area: ${areaDisplayTexts}`}
              >
                {renderAreas()}
              </ShiftInfoRow>
            ) : null}




            {
              <Status isNight={isNightShift ? 1 : 0}>
                {formatDRSStatus(data.status.id)}
              </Status>
            }

          </ShiftCard>
        )}
        {currentShiftCardView.length > 0 && (
          <ShiftCard isStoreData={true}>
            {currentShiftCardView.split(",").map((storeData: any) => {
              return storeData == "PDF & Photo" ? (
                <BlankWrapper>{storeData}</BlankWrapper>
              ) : (
                renderShiftRow(storeData)
              );
            })}
            {renderLabourMaterials()}
            <Status isNight={isNightShift ? 1 : 0}>
              {formatDRSStatus(data.status.id)}
            </Status>
          </ShiftCard>
        )}

      </ShiftBlock>


      {anchorEl !== null && (
        <ShiftMoreActionsMenu
          selectedShifts={selectedShifts}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          onEdit={() => {
            handleEdit();
            setAnchorEl(null);
          }}
          onOpenCrewpack={() => {
            window.open(`#/crew-pack/${data.id}`, "_blank");
            setAnchorEl(null);
          }}
          onOpenDRS={() => {
            setDrsDialogOpen(true);
            setAnchorEl(null);
          }}
          onOpenDuplicateDRS={() => {
            setDrsDuplicateDialogOpen(true);
            setAnchorEl(null);
          }}
          onDelete={() => {
            handleDelete();
            setAnchorEl(null);
          }}
          onDeleteMultiple={() => {
            handleDeleteMultiple();
            setAnchorEl(null);
          }}
          onDuplicate={() => {
            handleDuplicate();
            setAnchorEl(null);
          }}
          onCardOder={(type) => {
            handleShiftOrder(type);
            setAnchorEl(null);
          }}
        />
      )}
      {isDuplicateDialogOpen && (
        <DuplicateDialog
          shift={data}
          open={isDuplicateDialogOpen}
          onClose={() => setDuplicateDialogOpen(false)}
        />
      )}
      {drsDialogOpen && (
        <DRSRedirectConfirmDialog
          data={data.drsSummaryID}
          open={drsDialogOpen}
          onCancel={() => setDrsDialogOpen(false)}
          onClose={() => setDrsDialogOpen(false)}
        />
      )}
      {drsDuplicateDialogOpen && (
        <DRSDuplicateDialog
          data={data.drsSummaryID}
          open={drsDuplicateDialogOpen}
          onCancel={() => setDrsDuplicateDialogOpen(false)}
          onClose={() => setDrsDuplicateDialogOpen(false)}
          onSave={(drs: any) => handleDuplicateDrs(drs)}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<{
  isSelected: boolean;
  inDragLayer: boolean;
  isDragHappening: boolean;
}>`
  margin-bottom: 5px;
  position:relative;
  :last-child {
      margin - bottom: 0px;
  }
  ${(props) => {
    if (!props.inDragLayer && props.isSelected && props.isDragHappening) {
      return css`
        opacity: 0.3;
      `;
    }
  }}
`;
const ShiftCard = styled.div<{ isStoreData: boolean }>``;
const PlantBlock = styled.div<{ backcolor: string }>`
  border: 1px solid grey;
  border-radius: 4px;
  margin-top: 2px;
  ${(props) => {
    return css`
      background-color: ${props.backcolor};
    `;
  }}
`;
const TransportAreaType = styled.div`
  
`;

const TransportDelivery = styled.div`
`;
const TransportProfiling = styled.div`
`;
const TransportItem = styled.div`
`;
const TransportHeader = styled.div`
  font-weight:bold;
`;
const TransportArea = styled.div`
  
`;
const TransportAreaLabel = styled.div`
  font-size:10px;
`;
const PlantLabel = styled.label`
  color: #6e6e6e;
  font-size: 12px;
  display: inline-block;
  margin-left: 20px;
`;
const ShiftBlock = styled.div<{ isNight: number; isSelected: boolean }>`
  flex-grow: 1;
  height: 100%;
  position: relative;
  background-color: #f5f5f5;
  color: #6e6e6e;
  font-size: 10px;
  cursor: pointer;
  border-radius: 8px;
  font-weight: bold;
  padding: 0 8px 8px 8px;
  border: 1px solid #009688;
  ${(props) => {
    if (props.isNight) {
      if (props.isSelected) {
        return css`
          background-color: #9bcbfb;
          color: #fff;
          border-style: dashed;
        `;
      } else {
        return css`
          background-color: #009688;
          color: #fff;
          border: none;
        `;
      }
    }
  }}
  ${(props) => {
    if (!props.isNight && props.isSelected) {
      return css`
        background-color: #e6f2fe;
        border-style: dashed;
      `;
    }
  }}
`;

const MoreButton = styled(IconButton) <{ isnight: number }>`
  position: absolute;
  right: 0;
  top: 0;
  color: #0a9688;
  ${(props) => {
    if (props.isnight) {
      return css`
        color: #fff;
      `;
    }
  }}
`;
const MixTypeTonnesElement = styled.div`
 
`;
const ProjectNumELement = styled.span <{ colorCode: string,isNight: number}>`
background-color: #002A41;
padding-top: 5px;
padding-bottom: 5px;
padding-right: 5px;
padding-left: 5px;
font-size: 14px;
    color: #6e6e6e;
    font-weight: 400;

${(props) => {
  if (props.isNight) {
    return css`
      color: #f5f5f5;
    `;
  }
  if (props.colorCode) {
    return css`
      background-color: `+props.colorCode+`;
      padding-left: `+(props.colorCode == 'transparent' ? '0px' : '5px')+`;
    `;
  }
 
}}
${(props) => {
 
  if (props.colorCode) {
    return css`
      background-color: `+props.colorCode+`;
      padding-left: `+(props.colorCode == 'transparent' ? '0px' : '5px')+`;
    `;
  }
 
}}
`;
const ShiftNo = styled.div`
  font-size: 12px;
  height: 30px;
  line-height: 30px;
`;

const NavIcons = styled.div`
  white-space: nowrap;
`;
const BlankWrapper = styled.div`
  display: none;
`;
const NavIconButton = styled(IconButton) <{ isNight: number }>`
  color: #0a9688;
  ${(props) => {
    if (props.isNight) {
      return css`
        color: #f5f5f5;
      `;
    }
  }}
`;

const ShiftInfoRow = styled.div<{ isNight: number }>`
  height: 28px;
  line-height: 28px;
  font-size: 14px;
  font-weight: normal;
  color: #6e6e6e;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${(props) => {
    if (props.isNight) {
      return css`
        color: #f5f5f5;
      `;
    }
  }}
`;
const ShiftInfoRowItem = styled.div<{ isNight: number }>`
  height: 28px;
  line-height: 28px;
  font-size: 14px;
  font-weight: normal;
  color: #6e6e6e;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${(props) => {
    if (props.isNight) {
      return css`
        color: #f5f5f5;
      `;
    }
  }}
`;
const GridInfoRowItem = styled.div`
  
  font-size: 14px;
  font-weight: normal;
  color: #6e6e6e;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  
  
`;
const ShiftInfoRowItemDLVItem = styled.div<{ isNight: number }>`
 
  line-height: 28px;
  font-size: 14px;
  font-weight: normal;
  color: #6e6e6e;
  word-break: break-word
  ${(props) => {
    if (props.isNight) {
      return css`
        color: #f5f5f5;
      `;
    }
  }}
`;
const ShiftTransportInfoRow = styled.div<{ isNight: number }>`
  font-size: 14px;
  font-weight: normal;
  color: #6e6e6e;
  display: flex;
  flex-wrap: wrap;
  min-height: auto;
  white-space: pre-wrap;
  ${(props) => {
    if (props.isNight) {
      return css`
        color: #f5f5f5;
      `;
    }
  }}
`;

const Status = styled.div<{ isNight: number }>`
  border-radius: 16px;
  line-height: 16px;
  font-size: 10px;
  text-align: center;
  background-color: #0a9688;
  padding: 4px 10px;
  font-weight: normal;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 5px;
  ${(props) => {
    if (props.isNight) {
      return css`
        color: #0a9688;
        background-color: #fff;
      `;
    }
  }}
`;
export default observer(DRSItem);
