import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import AttachmentIcon from '@material-ui/icons/Attachment'
import { useSnackbar } from '@src/components/GlobalSnackbar'
import { Attachment } from '@src/types/Attachment'

const AcceptingFileMimeTypes = ['image/jpeg', 'image/png']
const AcceptingImageFileMimeTypes = ['image/jpeg', 'image/png']
const HTMLInputAccept = AcceptingFileMimeTypes.join(',') // 'image/jpeg, image/png, application/pdf'

interface Props {
  id: string,
  onSelect(files: FileList, imageFiles: FileList, assetId: any,drsSummaryId: any): void,
  attachments?: Attachment[],
}

const PreStartUploadAttachmentTrigger = (props: Props) => {
  const { id, onSelect, attachments } = props
  const fileInputRef = useRef<any>(null)
  const { alert } = useSnackbar()
  const handleFilesSelected = () => {
    if (fileInputRef) {
      checkFileTypesBeforeHandOver(fileInputRef.current.files)
    }
  }
  const checkFileTypesBeforeHandOver = (files: any) => {
    const imageAttachLimit: number = 20
    files = Array.from(files)
    let currentForm: any  = id.split('_b--g_');
    const validFiles = files.filter((file: File) => AcceptingFileMimeTypes.includes(file.type))
    const imageFiles = files.filter((file: File) => AcceptingImageFileMimeTypes.includes(file.type))
    const nonImageFiles = validFiles.filter((file: File) => !AcceptingImageFileMimeTypes.includes(file.type))
    const inValidFiles = files.filter((file: File) => !AcceptingFileMimeTypes.includes(file.type))
    let imageAttachments: Attachment[]
    { attachments ? imageAttachments = attachments.filter((attachment: Attachment) => AcceptingImageFileMimeTypes.includes(String(attachment.fileType.description))) : imageAttachments = [] }

    if (inValidFiles.length) {
      const inValidFileNames = inValidFiles.map((file: File) => `'${file.name}'`).join(', ')
      alert({
        type: 'warning',
        message: `${inValidFileNames} in the wrong file format. Please upload only .JPEG, .PNG or .PDF file formats`,
      })
    }

    if (validFiles.length) {
     let assetId = 0;
     let drsSummaryId = 0;
      if(currentForm.length > 0) {
        assetId = currentForm[0];
        drsSummaryId = currentForm[1];
      }

      onSelect(validFiles, imageFiles, assetId,drsSummaryId)
    }
  }
  return <Wrapper>
    <Label htmlFor={id}>
      +Attach
    </Label>
    <FileInput
      ref={fileInputRef}
      accept={HTMLInputAccept}
      id={id}
      type='file'
      onChange={handleFilesSelected}
    />
  </Wrapper>
}

const Wrapper = styled.div`
  width: 80px;
  height: 80px;
  text-align: center;
  line-height: 80px;
  background-color: #E7F4F2;
  color: #129688;
  text-decoration: underline;
`
const Label = styled.label`
  position: relative;
  width: 100%;
  display: block;
  height: 100%;
  padding: 0 16px;
  cursor: pointer;
  svg {
    position: relative;
    top: 6px;
  }
`
const FileInput = styled.input`
  display: none;
`

export default PreStartUploadAttachmentTrigger