import React, { useState, useEffect, useRef, SyntheticEvent } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Radio from '@material-ui/core/Radio'
import Button from '@material-ui/core/Button'
import ProjectNumberAutocomplete from './ProjectNumberAutocomplete'
import { DatePicker } from '../../../../components'
import { useCrewCalender } from '../StoreCrewCalender'
import { apiShiftCreate } from '../../../../apis/shift-create'
import MultiDatePicker from './MultiDatePicker'
import moment, { Moment } from 'moment'
import { apiShiftUpdate } from '../../../../apis/shift-update'
import IconButton from '@material-ui/core/IconButton'
import Edit from '@material-ui/icons/Edit'
import Delete from '@material-ui/icons/Delete'
import FileCopy from '@material-ui/icons/FileCopy'
import DuplicateDialog from './DuplicateDialog'
import { apiShiftRemove } from '../../../../apis/shift-remove'
import { useAppStore } from '../../../../AppStore'
import { CheckboxGroup } from '../../../DRS/legacy/Create/comps/f-EditableCheckboxGroup'
import { CONST_PROJECT_TYPES_ENUM_VALUES, CONST_PROJECT_TYPES_ENUM, getProjectTypeLabel } from '../../../../constants/project-types-enum'
import _get from 'lodash/get'
import { useSnackbar } from '../../../../components/GlobalSnackbar/useSnackbar'
import { useConfirm } from '@src/components/GlobalConfirmDialog'
import usePermission from '@src/utils/hooks/usePermission'
import { AutocompleteOnChangeItem } from '@src/types/AutocompleteOnChangeItem'
import { Project } from '@src/types/project/Project'
import { Shift } from '@src/types/Shift'
import { DRSStatus } from '@src/types/drs/DRSStatus'

interface Props {
  type: string,
  shiftData?: Shift,
  crewId: number,
  crewIndex: number,
  anchorEl: any,
  handleClose?(): void,
  initialStartDate?: Moment,
  initialEndDate?: Moment,
}

function CreateShiftPopover (props: Props) {
  const { 
    type,
    shiftData,
    crewId,
    crewIndex,
    anchorEl,
    handleClose = () => {}, 
    initialStartDate,
    initialEndDate,
  } = props
  const { canCreateShift, canEditShift } = usePermission()
  const popoverActions = useRef<any>()
  const open = Boolean(anchorEl)
  const isCreate = type === 'create'
  const [ shiftType, setShiftType ] = useState<any>('') // shiftType for raw value
  const [ selectedProject, setSelectedProject ] = useState<Project>()
  const selectedProjectValueLabel = selectedProject && (selectedProject.id || selectedProject.projectInternalId) ? {
    value: selectedProject.id,
    label: selectedProject.name || `${selectedProject.projectInternalId}`,
  } : { value: '', label: ''}
  const JobTypeOptions = CONST_PROJECT_TYPES_ENUM_VALUES.filter(item => item !== CONST_PROJECT_TYPES_ENUM['ASPHALT-SPRAY-SEAL']).map(item => {
    return {
      value: item,
      label: getProjectTypeLabel(item),
    }
  })
  const [ selectedJobTypeItems, setSelectedJobTypeItems ] = useState<any[]>(JobTypeOptions.map(item => ({ id: item.value })))
  // const showJobType = selectedProject.value && (_get(selectedProject, `type.id`) === CONST_PROJECT_TYPES_ENUM['ASPHALT-SPRAY-SEAL'])
  const showJobType = Boolean(selectedProject)

  const { createShift, goRefresh } = useCrewCalender()
  const [ validated, setValidated ] = useState<any>(false)
  const [ dates, setDates ] = useState<Moment[]>([])
  const [ date, setDate ] = useState<Moment>()
  const [ isDialogOpen, setIsDialogOpen ] = useState<any>(false)
  const { regionId, serialId } = useAppStore()
  const { alert } = useSnackbar()
  const { confirm } = useConfirm()

  useEffect(() => {
    if (popoverActions.current) {
      popoverActions.current.updatePosition()
    }
  }, [validated, selectedProject])

  useEffect(() => {
    if (isCreate) {
      if (!initialStartDate || !initialEndDate) {
        return
      }
      const start: Moment = initialStartDate.clone().startOf('day')
      const end: Moment = initialEndDate.clone().startOf('day')
      let result = []
      while (start.diff(end) <= 0) {
        result.push(start.clone())
        start.add(1, 'days')
      }
      setDates(result)
    } else {
      if (shiftData) {
        setShiftType(shiftData.plan.shiftType.id)
        setSelectedProject({
          projectInternalId: shiftData.projectInternalId,
          id: shiftData.projectId,
          name: shiftData.projectName,
        })
        setDate(moment(shiftData.date))
        switch (shiftData.drsProjectType.id) {
          case 'ASPHALT': {
            setSelectedJobTypeItems([ { id: 'ASPHALT' }])
            break
          }
          case 'SPRAY-SEAL': {
            setSelectedJobTypeItems([ { id: 'SPRAY-SEAL' }])
            break
          }
          case 'ASPHALT-SPRAY-SEAL': {
            setSelectedJobTypeItems([{ id: 'ASPHALT' }, { id: 'SPRAY-SEAL' }])
            break
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handleShiftTypeChange = (e: SyntheticEvent) => {
    setShiftType((e.target as HTMLInputElement).value)
  }
  const handleEdit = () => {
    window.open(`#/crew-pack/${shiftData!.id}`, '_blank')
  }
  const handleCopy = () => {
    setIsDialogOpen(true)
  }
  const handleDialogClose = () => {
    setIsDialogOpen(false)
  }
  const beforeRemove = async () => {
    try {
      const result = await confirm({
        title: 'Confirm Deletion of Shift and DRS Data',
        message: 'This will permanently delete any saved DRS information from the system and cannot be undone',
        confirmButtonText: 'Delete DRS Data',
      })
      handleRemove()
      // console.log('resolved', result)
    } catch (err) {
      // console.log('rejected')
    }
  }
  const handleRemove = async () => {
    const result = await apiShiftRemove({ 
      shiftId: shiftData!.id,
      serialId,
    })
    goRefresh()
    handleClose()
    alert({
      message: `Shift #${shiftData!.id} removed.`
    })
  }
  const handleOk = () => {
    if (shiftType && selectedProject && selectedJobTypeItems.length) {
      let drsProjectTypeId
      if (selectedJobTypeItems.length === 1) {
        drsProjectTypeId = selectedJobTypeItems[0].id
      } else {
        drsProjectTypeId = CONST_PROJECT_TYPES_ENUM['ASPHALT-SPRAY-SEAL']
      }
      if (isCreate) {
        if (!canCreateShift) {
          return
        }
        apiShiftCreate({
          project: selectedProject,
          shiftTypeId: shiftType,
          dates: dates.map(date => date.toISOString()),
          crewId: crewId,
          drsProjectTypeId,
          serialId: serialId,
          street: '',
          nearestCrossStreet: '',
          suburb: '',
          ShiftSealingRequirment:'',
          ShiftSalesOrder:'',
		  IsCostOnlyShift: false
        }).then((response: any) => {
          if (response.data) {
            goRefresh()
          }
        })
        handleClose()
      } else {
        if (!canEditShift) {
          return
        }
        apiShiftUpdate({
          shiftId: shiftData!.id,
          description: shiftData!.description,
          project: selectedProject,
          shiftTypeId: shiftType,
          date: date!.toISOString(),
          crewId: crewId,
          drsProjectTypeId: drsProjectTypeId,
          serialId: serialId,
          street: '',
          nearestCrossStreet: '',
          suburb: '',
          planKMPlantToDepot:0,
          ShiftSealingRequirment:'',
          ShiftSalesOrder:'',
		  IsCostOnlyShift: false
        }).then((response: any) => {
          if (response.data) {
            goRefresh()
          }
        })
        handleClose()
      }
    } else {
      setValidated(true)
    }
  }
  return (
    <Popover
      id={'view shift'}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      action={popoverActions}
    >
      <Wrapper>
        <Header>
          <Title>{ isCreate ? 'Create Shift' : 'Shift'}</Title>
          { !isCreate && <Actions>
            <IconButton 
              id='button-duplicate-shift'
              size='small' 
              disabled={!canCreateShift}
              onClick={() => handleCopy()}
            >
              <FileCopy />
            </IconButton>
            <IconButton
              id='button-edit-shift'
              size='small'
              onClick={handleEdit}
            >
              <Edit />
            </IconButton>
            {
              shiftData && 
              (shiftData.status.id === DRSStatus.DRAFT
                || shiftData.status.id === DRSStatus.CANCELLED
                || shiftData.status.id === DRSStatus.SUBMITTED_FORMAN
                ) &&
              <IconButton
                id='button-remove-shift'
                size='small'
                disabled={!canEditShift}
                onClick={beforeRemove}
              >
                <Delete />
              </IconButton>
            }
          </Actions>
          }
        </Header>
        <Body>
          <FormItem>
            <FormControl fullWidth>
              <ProjectNumberAutocomplete 
                disabled={!isCreate}
                regionId={regionId}
                onChange={ (item: AutocompleteOnChangeItem<Project>) => {
                  setSelectedProject(item.raw) 
                  switch (item.raw.type!.id) {
                    case 'ASPHALT': {
                      setSelectedJobTypeItems([ { id: 'ASPHALT' }])
                      break
                    }
                    case 'SPRAY-SEAL': {
                      setSelectedJobTypeItems([ { id: 'SPRAY-SEAL' }])
                      break
                    }
                    case 'ASPHALT-SPRAY-SEAL': {
                      setSelectedJobTypeItems([{ id: 'ASPHALT' }, { id: 'SPRAY-SEAL' }])
                      break
                    }
                  }
                }}
                selectedItem={selectedProjectValueLabel}
              />
              { !(validated && !selectedProject) ? null :
                <FormHelperText error>Required. Please select project.</FormHelperText>
              }
            </FormControl>
          </FormItem>
          
          <FormItem>
            <FormControl>
              <FormLabel>Select Shift</FormLabel>
              <RadioGroup
                aria-label='shift type'
                name='shift type'
                value={shiftType}
                onChange={handleShiftTypeChange}
              >
                <FormControlLabel value='DAY' control={<Radio />} label='Day Shift' />
                <FormControlLabel value='NIGHT' control={<Radio />} label='Night Shift' />
              </RadioGroup>
              { !(validated && !shiftType) ? null :
                <FormHelperText error>Required. Please select shift type.</FormHelperText>
              }
            </FormControl>
          </FormItem>

          { showJobType && <FormItem>
            <FormControl>
              <FormLabel>Pick Job Type</FormLabel>
              <CheckboxGroup
                disabled={!isCreate}
                selectedItems={selectedJobTypeItems}
                options={JobTypeOptions}
                onChange={(selectedItems: any[]) => setSelectedJobTypeItems(selectedItems)}
              />
            </FormControl>
          </FormItem>
          }

          { isCreate && <FormItem>
            <FormControl fullWidth>
              <FormLabel>Dates</FormLabel>
              <MultiDatePicker
                selectedItems={dates}
                onChange={(date: Moment) => {
                  const index: number = dates.findIndex(item => {
                    return moment(date).isSame(moment(item), 'date')
                  })
                  // console.log(index)
                  if (index !== -1) {
                    setDates([...dates.slice(0, index), ...dates.slice(index + 1)])
                  } else {
                    setDates([...dates, date])
                  }
                }}
              />
            </FormControl>
          </FormItem>
          }
          {
            !isCreate && <FormItem>
            <FormControl>
              <FormLabel>Date</FormLabel>
              <DatePicker 
                isKeyboardDatePicker={true}
                value={date}
                onChange={(val: Moment) => setDate(val)}
                format='DD/MM/YYYY'
              />
            </FormControl>
          </FormItem>
          }
        </Body>
        <BottomActions>
          <Button onClick={handleOk} color='primary'>
            {isCreate ? 'Create Shift' : 'Update Shift'}
          </Button>
        </BottomActions>
      </Wrapper>
      <DuplicateDialog
        shift={shiftData!}
        open={isDialogOpen}
        onClose={handleDialogClose}
      />
    </Popover>
  )
}

const Wrapper = styled.div`
  width: 328px;
  /* height: 386px; */
`
const Header = styled.div`
  display: flex;
  padding: 0 24px;
  background-color: #009688;
`
const Title = styled.div`
  flex-grow: 1;
  height: 60px;
  line-height: 60px;
  color: #fff;
  font-size: 16px;
`
const Body = styled.div`
  padding: 24px;
`

const Actions = styled.div`
  display: flex;
  padding: 18px 0;
  svg {
    font-size: 16px;
    color: #fff;
  }
`
const FormItem = styled.div`
  margin-bottom: 24px;
  :last-child {
    margin-bottom: 0;
  }
`
const BottomActions = styled.div`
  flex: 0 0 auto;
  display: flex;
  padding: 8px;
  align-items: center;
  justify-content: flex-end;
`

export default observer(CreateShiftPopover)