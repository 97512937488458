import { ax } from '@src/utils/axios'
import { BASE_API_URL } from '@src/constants'
import { PackFormType } from '@src/types/PackFormType'

const Mocked = {
  data: {
    document: {
      id: 4,  // The Document ID of the current document

      originalName: "new image 2020.jpg", // The original file name used when the file was uploaded
      name: "Physical-File-Name", // The physical file name used for storage
      location: "/path/to/file-folder", // The physical location of the file

      fileType: {
        id: 3,
        description: "PDF",
        mime: 'image/png'
      },  // A file type object, current values for description are: JPG, PNG, and PDF

      roleId: "PROJECT_MANAGER", // The role of the user uploaded the file
      email: "yu.zhao@boral.com.au",  // The email of the user uploaded the file
      serialId: 1,  // The Serial ID of the user uploaded the file
    },
  }
}

interface Options {
  formType: PackFormType,
  formId: number | string,
  roleId: string,
  serialId: number,
  file: File,
  projectInternalId: any,
  IsSupervisor?: string,
  IsAdministrator?: string,
  IsResourceScheduler?: string,
  IsProjectManager?: string,
  IsForeman?: string,
  cpDocComment?: string,
  assetId?: string,
  assetDrsSummmaryId?: string,
  assetShiftId?: string,
  typeId?: string
}

export const apiUploadFilePrestart = ({
  formType,
  formId,
  roleId,
  serialId,
  file,
  projectInternalId,
  IsSupervisor,
  IsAdministrator,
  IsResourceScheduler,
  IsProjectManager,
  IsForeman,
  cpDocComment,
  assetId,
  assetDrsSummmaryId,
  assetShiftId,
  typeId

}: Options) => {
  // return new Promise((resolve, reject) => {
  //   setTimeout(() => {
  //     // reject()
  //     resolve({
  //       ...Mocked,
  //       data: {
  //         ...Mocked.data,
  //         document: {
  //           ...Mocked.data.document,
  //           id: Math.random(),
  //           originalName: file.name,
  //         }
  //       }
  //     })
  //   }, Math.random() * 5000)
  // })
  const data = new FormData()
  data.append('file', file)
  const options = {
    method: 'POST',
    url: `${BASE_API_URL}/v2/documents/${formType}/${formId}`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
    params: {
      reqRole: roleId,
      reqSid: serialId,
      projectInternalId,
      IsSupervisor,
      IsAdministrator,
      IsResourceScheduler,
      IsProjectManager,
      IsForeman,
      cpDocComment,
      assetId,
      assetDrsSummmaryId,
      assetShiftId,
      typeId

    }
  }
  return ax(options)
}