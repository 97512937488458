import React, { useState, useEffect, SyntheticEvent } from 'react'
import styled from 'styled-components'
import { useParams, useHistory } from 'react-router-dom'
import DashboardProjects from '../DashboardProjects/DashboardProjects'
import DashboardCalendar from '../DashboardCalendar/DashboardCalendar'
import DashboardTasks from '../DashboardTasks/DashboardTasks'
import Tabs from './parts/Tabs'
import Container from '@material-ui/core/Container'
import Welcome from './parts/Welcome'
import Grid from '@material-ui/core/Grid'
import DateSwitch from '@src/modules/DashboardCalendar/CrewCalendarView/Toolbar/DateSwitch'
import { useSelectedShifts } from '@src/utils/hooks/StoreSelectedShifts';
import { checkToken } from '../GenerateToken';
import { useCrewCalender } from '../DashboardCalendar/CrewCalendarView/StoreCrewCalender'
import { IconButton } from '@material-ui/core'
import { Fullscreen, FullscreenExit } from '@material-ui/icons'


  const DashboardPage = ({
  }) => {
  const depotNoteCollapse = localStorage.getItem('depotNotesShow');
  const [tabIndex, setTabIndex] = useState<any>(0)
  const [shiftNoteStyle, setShiftNoteStyle] = useState<string>(depotNoteCollapse ? depotNoteCollapse : 'shfitNoteClassMin')
  const { part } = useParams<{ part: 'projects' | 'calendar' | 'tasks' }>()
  const possiblePartValues = ['projects', 'calendar', 'tasks']
  const history = useHistory()
  const { clearSelectedShifts } = useSelectedShifts()
  const { fullScreen,setFullScreen} = useCrewCalender()

  useEffect(() => {
    setTabIndex(possiblePartValues.indexOf(part))
  }, [part])

  const handleNoteClick = () => {
    const show = shiftNoteStyle == 'shfitNoteClass' ? 'shfitNoteClassMin': 'shfitNoteClass';
    shiftNoteStyle == 'shfitNoteClass' ? setShiftNoteStyle('shfitNoteClassMin') : setShiftNoteStyle('shfitNoteClass');
    localStorage.setItem('depotNotesShow', show); 
  }
  const handleClick = (e: React.SyntheticEvent<any>) => {
    e.stopPropagation()
    //console.log(`DashboardPage onClick()`)
    clearSelectedShifts()
  }

  const handleTabClick = async (label: string, index: number) => {
    if(possiblePartValues.indexOf(part) != index) {
      const status: any = await checkToken();
      if(index === 2 && !status) {
        history.push({
          pathname: `/dashboard/${possiblePartValues[0]}`,
        })
      }
      history.push({
        pathname: `/dashboard/${possiblePartValues[index]}`,
      })
    }
  }
  const handleFullScreen = () => {
    
      document.getElementById('appBar').removeAttribute("style");
      document.getElementById('mainScreenTabs').removeAttribute("style");
      document.getElementById('exitFullScreenMode').style.display = 'none';
      document.getElementById("mainScreenTabs").style.position =  'sticky';
      document.getElementById("mainScreenTabs").style.top = '0px';
      document.getElementById("mainScreenTabs").style.backgroundColor = 'white';
      document.getElementById("mainScreenTabs").style.zIndex = '1299';
      document.getElementById("mainScreenTabs").style.marginLeft ='-22px';
      document.getElementById("mainScreenTabs").style.padding =  '12px';
      document.getElementById("mainScreenTabs").style.width ='calc(100% + 44px)';
      document.getElementById('mainWrapperElement').style.top ='85px';
    
    setFullScreen(false);
  }
  const renderParts = () => {
    const partIndex = possiblePartValues.indexOf(part)
    return [<DashboardProjects />, <DashboardCalendar shiftNoteStyle={shiftNoteStyle} noteClickEvent={handleNoteClick} />, <DashboardTasks />][partIndex]
  }

  return <Container maxWidth={false} onClick={handleClick} style={{ position: 'relative' }}>
    <Wrapper>
      {part === possiblePartValues[0] && <Welcome />}
      <TabsWrapper >
      {  <IconButton 
              id="exitFullScreenMode"
              size='small'
              onClick={handleFullScreen}
              style={{cursor:'pointer',color:'red',display:'none',float:'right',zIndex:9999}} >
             <FullscreenExit  />
            </IconButton>}
        <Grid container   id="mainScreenTabs" style={{position:'sticky',top:'0px',backgroundColor:'white',zIndex:1299,marginLeft:'-22px',padding: '12px',width:'calc(100% + 44px)'}}>
          <Grid item xs={5}>
            <Tabs 
              labels={['Projects', 'Depot Calendar'/* , 'Maps' */]}
              value={tabIndex}
              onClick={handleTabClick}
            />
          </Grid>
          <Grid item xs={7}>
            {part === possiblePartValues[1] && <DateSwitch handleNoteClick={handleNoteClick} shiftNoteStyle={shiftNoteStyle} />}
          </Grid>
        </Grid>

        <PartsWrapper>
          {renderParts()}
        </PartsWrapper>
      </TabsWrapper>
    </Wrapper>
  </Container >
}

const Wrapper = styled.div`
`
const TabsWrapper = styled.div`
`
const PartsWrapper = styled.div`
  /* margin-top: 40px; */
`


export default DashboardPage