export enum ATPStatus {
	New = "new",
	Pending = "Pending",
	Completed = "Completed",
	Updated = "updated",
  }
  
  export function formatATPStatus(status: ATPStatus) {
	switch (status) {
	  case ATPStatus.New:
		return 'New'
	  case ATPStatus.Pending:
		return 'Pending'
	  case ATPStatus.Completed:
		return 'Completed'
	  case ATPStatus.Updated:
		return 'Updated'
	}
  }
  