export enum QASStatus {
  New = "new",
  Completed = "completed",
  Updated = "updated",
}


export function formatQASStatus(status: QASStatus) {
  switch (status) {
    case QASStatus.New:
      return 'New'
    case QASStatus.Completed:
      return 'Completed'
    case QASStatus.Updated:
      return 'Updated'
  }
}
