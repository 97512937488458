import React, { useState, useEffect } from "react";
import styled from "styled-components";
import _orderBy from "lodash/orderBy";
import _clone from "lodash/clone";
import _union from "lodash/union";
import _find from "lodash/find";
import _some from "lodash/some";
import _reject from "lodash/reject";
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import PreStartUploadAttachmentTrigger from "../parts/UploadTrigger";
import FormModalTextField from "@src/components/FormModal/Textfield";
export interface MobileAssetDocumentDialogProps {
  open: boolean;
  onClose(): void;
  onCancel(): void;
  onSuccess(): void;
  editMode: boolean;
  isLoader: boolean;
  onSave(
    selectedFiles: any,
    assetId: any,
    readingKM: any,
    readingHr: any
  ): void;
  currentReadingKM?: any;
  currentReadingHour?: any;
  formId?: any;
  assetType?: any;
}

export interface DRSMobileAssetDisplay {
  drsAssetReadingHour: string;
  drsAssetReadingKM: string;
  cpFileName: string;
  mapStatusID: number;
}

const MobileAssetDocumentDialog = (props: MobileAssetDocumentDialogProps) => {
  const {
    open,
    onClose,
    onCancel,
    onSuccess,
    editMode,
    onSave,
    isLoader,
    currentReadingKM,
    currentReadingHour,
    formId,
    assetType,
  } = props;

  const [readingKM, setReadingKM] = useState(null);
  const [readingHR, setReadingHR] = useState(null);

  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(isLoader);
  const [fieldsInError, setFieldsInError] = useState<any[]>([]);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState<any[]>([]);

  const ASSET_UNIT_MAP = {
    1: 1, // Heavy Vehicle → unit 1
    2: 1, // FMAS → unit 1
    3: 1, // MPAVTack Coater → unit 1
    4: 3, // Sprayer → unit 3
    5: 1, // CrewJob Truck → unit 1
    6: 1, // Trailer → unit 1
    7: 2, // Paver → unit 2
    8: 2, // Roller → unit 2
    9: 2, // TowTractor Broom → unit 2
    10: 2, // MTV → unit 2
    11: 2, // Front End Loader → unit 2
    12: 2, // Skid Steer → unit 2
    13: 2, // Profiler → unit 2
    14: 2, // Forklift → unit 2
  };

  const isKMRequired =
    ASSET_UNIT_MAP[assetType] === 1 || ASSET_UNIT_MAP[assetType] === 3;
  const isHourRequired =
    ASSET_UNIT_MAP[assetType] === 2 || ASSET_UNIT_MAP[assetType] === 3;

  useEffect(() => {
    setIsLoading(isLoader);
  }, [isLoader]);

  useEffect(() => {
    setIsSubmitEnabled(
      selectedFiles.length > 0 &&
        (!isKMRequired || (isKMRequired && readingKM)) &&
        (!isHourRequired || (isHourRequired && readingHR))
    );
  }, [readingKM, readingHR, selectedFiles, isKMRequired, isHourRequired]);

  const handleOk = async () => {
    if (selectedFiles.length === 0) {
      alert("Please select an image to save.");
      return;
    }
    setIsLoading(true);
    onSave(selectedFiles, formId, readingKM, readingHR);
  };

  const onSelectedFile = (validFiles: any, imageFiles: any) => {
    console.log(imageFiles);
    setSelectedFiles(imageFiles);
  };

  return (
    <Container maxWidth="sm">
      <Dialog fullWidth={true} maxWidth={"sm"} open={open} onClose={onClose}>
        <DialogTitle style={{ textAlign: "center" }}>
          {"Upload File and Currernt KM/Hour"}
        </DialogTitle>
        <DialogContent>
          <SectionWrapper>
            <Section>
              <DialogContentText></DialogContentText>
            </Section>

            <Section>
              <FieldWrapper>
                {(ASSET_UNIT_MAP[assetType] === 1 ||
                  ASSET_UNIT_MAP[assetType] === 3) &&
                  (currentReadingKM ? (
                    <label>Asset Kms: {currentReadingKM}</label>
                  ) : (
                    <label>Asset Kms is not available</label>
                  ))}
                {(ASSET_UNIT_MAP[assetType] === 1 ||
                  ASSET_UNIT_MAP[assetType] === 3) && (
                  <FormModalTextField
                    id="readingKM"
                    label={"Cuurent KM"}
                    defaultValue={""}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      var val = e.target.value;
                      setReadingKM(val);
                    }}
                    onBlur={(e) => {
                      setReadingKM(e.target.value);
                    }}
                    helperText={
                      _some(fieldsInError, { key: "Cuurent KM" })
                        ? _find(fieldsInError, { key: "Cuurent KM" })
                            .helperTextError
                        : ""
                    }
                    error={_some(fieldsInError, { key: "Cuurent KM" })}
                  ></FormModalTextField>
                )}

                {(ASSET_UNIT_MAP[assetType] === 2 ||
                  ASSET_UNIT_MAP[assetType] === 3) &&
                  (currentReadingHour ? (
                    <label>Asset Hours: {currentReadingHour}</label>
                  ) : (
                    <label>Asset Hours is not available</label>
                  ))}
                {(ASSET_UNIT_MAP[assetType] === 2 ||
                  ASSET_UNIT_MAP[assetType] === 3) && (
                  <FormModalTextField
                    label={"Cuurent Hours"}
                    defaultValue={""}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      var val = e.target.value;
                      setReadingHR(val);
                    }}
                    onBlur={(e) => {
                      setReadingHR(e.target.value);
                    }}
                    helperText={
                      _some(fieldsInError, { key: "Cuurent Hours" })
                        ? _find(fieldsInError, { key: "Cuurent Hours" })
                            .helperTextError
                        : ""
                    }
                    error={_some(fieldsInError, { key: "Cuurent Hours" })}
                  ></FormModalTextField>
                )}
              </FieldWrapper>
              {isLoading && (
                <SpinnerWrapper>
                  <Spinner size={24} />
                </SpinnerWrapper>
              )}
              {!isLoading && (
                <TriggerWrapper>
                  <PreStartUploadAttachmentTrigger
                    id="drs-attach-actual"
                    onSelect={onSelectedFile}
                  />
                  {selectedFiles.length > 0 && (
                    <label> {selectedFiles[0].name} </label>
                  )}
                </TriggerWrapper>
              )}
            </Section>
          </SectionWrapper>
        </DialogContent>
        <DialogActions>
          {
            <React.Fragment>
              <Button onClick={onCancel}>Cancel</Button>
              <Button
                disabled={isLoading || !isSubmitEnabled}
                color="primary"
                variant="contained"
                onClick={handleOk}
              >
                {isLoading ? "Saving..." : "Save"}
              </Button>
            </React.Fragment>
          }
        </DialogActions>
      </Dialog>
    </Container>
  );
};

const SectionWrapper = styled.div``;

const Section = styled.div``;

const Spinner = styled(CircularProgress)`
  margin-right: 10px;
  position: relative;
`;
const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`;
const TriggerWrapper = styled.div`
  margin-right: 16px;
`;
const SortControlsWrapper = styled.div`
  display: flex;
  margin-top: 5px;
  justify-content: center;
  width: 75%;
`;

const ButtonSpinner = styled(CircularProgress)`
  margin-right: 10px;
`;
const FieldWrapper = styled.div`
  padding: 0 1%;
  margin-bottom: 40px;
  margin-top: 25px;
  width: 400px;
`;
export default MobileAssetDocumentDialog;
