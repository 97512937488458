import React, { useState } from "react";
import styled from "styled-components";
import {
  ProjectTypeId,
  normalizeProjectTypeId,
} from "@src/types/ProjectTypeId";
import FormPanel from "@src/components/FormPanel/FormPanel";
import FormPAETable, { PAETableRowType } from "@src/components/Form/PAETable";
import useModal from "@src/components/FormModal/useModal";
import { useSnackbar } from "@src/components/GlobalSnackbar";
import { useParams } from "react-router";
import { PackFormType } from "@src/types/PackFormType";
import { FormTableField, FormTableFieldType } from "@src/components/Form/Table";
import { DRSMobileAsset } from "@src/types/drs/mobile-assets/MobileAsset";

import PreStartUploadAttachmentTrigger from "./UploadTrigger";

import {
  DRSPageType,
  DRSSectionName,
  mapDRSPageTypeToPAE,
  mapDRSPageTypeToRoleId,
} from "@src/modules/DRS/DRSPage";
import { useConfirm } from "@src/components/GlobalConfirmDialog";
import MarginTop from "@src/components/Layout/MarginTop";
import CompCreateButton from "@src/components/Buttons/CreateButton";
import {
  useDRS,
  transformSectionNameToDataSectionName,
} from "@src/modules/DRS/useDRS";
import Modal from "@src/components/FormModal/Modal";
import { useAppStore } from "@src/AppStore";
import _get from "lodash/get";
import { doubleUp } from "@src/modules/DRS/utils/doubleUp";
import { PAE } from "@src/types/drs/PAE";
import DRSReworkAssetsFields, {
  DRSReworkAssetDisplay,
  DRSAssetDepotType,
} from "../../2-Rework/parts/ReworkAssetsFields";
import { mapMoreActions } from "@src/modules/DRS/utils/mapMoreActions";
import { genericUpdateDRSSection } from "@src/modules/DRS/utils/genericUpdateDRSSection";
import { DRSReworkAssetPAE } from "@src/types/drs/rework/Rework";
import { DRSReworkAssetRow } from "../../2-Rework/parts/ReworkAssets";
import {
  transformAssetData,
  transformMobileAssetData,
} from "../../2-Rework/utils/transformFromFormData";
import DRSMobileAssetsFields, {
  DRSMobileAssetDisplay,
} from "./MobileAssetsFields";
import { DRSMobileAssetPAE } from "@src/types/drs/mobile-assets/MobileAsset";
import { compressImageFile } from "@src/apis/files/compress-image-file";
import { apiUploadFile } from "@src/apis/files/upload-file";
import { apiUploadFilePrestart } from "@src/apis/files/upload-file-prestart";
import MobileAssetDocumentDialog from "./MobileAssetDocumentDialog";

export interface DRSMobileAssetRow extends DRSMobileAssetDisplay {
  _key_: string;
  _type_: PAETableRowType;
  _no_?: number;
  _editable_: boolean;
}
const NULL_VALUES = {
  comments: null,
  hours: null,
  mobileAsset: null,
};
const ZERO_VALUES = {
  comments: "",
  hours: "0",
  mobileAsset: null,
};

//1 → KM 2 → Hour 3 → Both
const UNIT_COLOR_MAP = {
  1: "red",
  2: "blue",
  3: "green",
};

// Map each asset ID to its unit
const ASSET_UNIT_MAP = {
  1: 1, // Heavy Vehicle → unit 1
  2: 1, // FMAS → unit 1
  3: 1, // MPAVTack Coater → unit 1
  4: 3, // Sprayer → unit 3
  5: 1, // CrewJob Truck → unit 1
  6: 1, // Trailer → unit 1
  7: 2, // Paver → unit 2
  8: 2, // Roller → unit 2
  9: 2, // TowTractor Broom → unit 2
  10: 2, // MTV → unit 2
  11: 2, // Front End Loader → unit 2
  12: 2, // Skid Steer → unit 2
  13: 2, // Profiler → unit 2
  14: 2, // Forklift → unit 2
};

interface Props {
  projectTypeId: ProjectTypeId.Asphalt | ProjectTypeId.SpraySeal;
  type: any;
}

const PartDRSMobileAssets = (props: Props) => {
  const { projectTypeId, type } = props;
  const normalizedType = normalizeProjectTypeId(projectTypeId);
  const { id } = useParams<{ id: string }>();
  const {
    drs,
    updateViaPath,
    updateSectionViaPath,
    drsPageType,
    fetchDRS,
    refresh,
  } = useDRS();
  const isPlan = drsPageType === DRSPageType.Plan;
  const isEdit = drsPageType === DRSPageType.Edit;
  const updatingColumn: PAE.Plan | PAE.Edit = isPlan ? PAE.Plan : PAE.Edit;
  const { alertError, alertDone } = useSnackbar();
  const { confirm } = useConfirm();

  const [open, modalData, openAndSetData, closeModal] =
    useModal<DRSMobileAssetRow>({});
  const { depotId, stateId, serialId } = useAppStore();
  const [isLoading, setIsLoading] = useState<any>(false);
  const [isDialogueOpen, setisDialogueOpen] = useState<any>(false);
  const [currentReadingKM, setCurrentReadingKM] = useState<any>("");
  const [currentReadingHour, setCurrentReadingHour] = useState<any>(null);
  const [currentAssetId, setCurrentAssetId] = useState<any>(null);
  const [currentType, setCurrentType] = useState<any>(null);
  const [currentForm, setCurrentForm] = useState<any>(null);
  if (drs === null) {
    return null;
  }

  const onSave = async (form: DRSMobileAssetRow) => {
    if (modalData === null) {
      addItem(form);
    } else {
      editItem(form);
    }
  };
  const fieldKeys: any = [
    "assetDepotType",
    "mobileAsset",
    "assetDescription",
    "hours",
    "backupMachine",
  ];
  function handleDialouge(
    assetId: any,
    currentKM: any,
    currentHr: any,
    type: any,
    item: any
  ) {
    setCurrentReadingKM(currentKM);
    setCurrentReadingHour(currentHr);
    setCurrentType(type);
    setCurrentAssetId(assetId);
    setCurrentForm(item);
    setisDialogueOpen(true);
  }
  async function handleSave(
    selectedFiles: any,
    formId: any,
    readingKM: any,
    readingHr: any,
    currentFormData: any
  ) {
    const item = currentFormData;

    item["drsAssetReadingHour"] = readingHr ? parseInt(readingHr) : null;
    item["drsAssetReadingKM"] = readingKM ? parseInt(readingKM) : null;
    editItem(item);
    await handleFileSelect(selectedFiles, selectedFiles, formId); //todo open when KM/HR works
    setisDialogueOpen(false);
    alertDone();
    refresh();
  }
  const handleFileSelect = async (
    validFiles: FileList,
    imageFiles: FileList,
    formId: any
  ) => {
    try {
      let localData = JSON.stringify(drs);
      let localParseData = JSON.parse(localData);
      let assetShiftId: any = localParseData.jobDetails.shift.id;
      let drsSummaryId: any = drs.summary.summary.drsSummaryId;
      setIsLoading(true);
      Promise.all(
        Array.from(imageFiles).map((imageFile) => {
          let maxWidth: number = 1000;
          let maxHeight: number = 1000;
          let maxFileSize: number = 250000;

          return compressImageFile({
            imageFile,
            maxWidth,
            maxHeight,
            maxFileSize,
          });
        })
      )
        .then((compressedImageFiles) => {
          return Promise.all(
            Array.from(compressedImageFiles).map((file: File) => {
              return apiUploadFilePrestart({
                formType: PackFormType.PAPER_PRESTART,
                formId: formId,
                roleId: mapDRSPageTypeToRoleId(drsPageType),
                serialId,
                file: file,
                assetId: formId,
                assetDrsSummmaryId: drsSummaryId,
                typeId: drsSummaryId,
                assetShiftId: assetShiftId,
                projectInternalId: drs.jobDetails.project.projectInternalId,
              });
            })
          );
        })

        .then(() => {
          setIsLoading(false);
          alertDone();
          refresh();
        });
    } catch (e) {
      //alertError()
    }
  };
  if (type === 1) fieldKeys.push("notUsed");
  const tblFields: FormTableField<DRSMobileAssetDisplay>[] = [
    {
      id: "DRSAssetDepotType",
      key: "assetDepotType",
      type: FormTableFieldType.Select,
      label: "Asset Depot",
      format: (cellData: any) => {
        if (cellData) {
          return cellData.description;
        }
        return "";
      },
    },
    {
      id: "drs-rework-asset",
      key: "mobileAsset",
      type: FormTableFieldType.Autocomplete,
      label: "Asset Number",
      format: (item: any) => {
        return item ? item.id : "";
      },
      alsoUpdates: ["assetDescription"],
      alsoUpdatesFunc: (item: any, form: any) => {
        let valDesc: any = document.getElementById("assetDescription");
        let valDescLabel: any = document.getElementById(
          "assetDescription-label"
        );
        valDesc.value = item.description;
        form.assetDescription = item.description;
        valDescLabel.setAttribute(
          "class",
          "MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink Mui-focused Mui-focused"
        );
        valDescLabel.setAttribute("data-shrink", true);
        return [item.description];
      },
    },
    {
      id: "assetDescription",
      key: "assetDescription",
      type: FormTableFieldType.Text,
      label: "Asset Description",
      disabled: true,
    },
    {
      id: "mobile-asset-hours",
      key: "hours",
      type: FormTableFieldType.Select,
      label: "Hours",
      format: (cellData: any) => {
        return cellData;
      },
    },
    {
      id: "mobile-asset-backup",
      key: "backupMachine",
      type: FormTableFieldType.CheckboxGroup,
      label: "Backup Machine",
      options: [{ value: "Y", label: "Required as backup machine only?" }],
      format: (cellData: any) => {
        if (cellData == true) {
          return "Y";
        } else {
          return "N";
        }
      },
    },
    {
      id: "mobile-asset-paperPreStart",
      key: "cpFileName",
      type: FormTableFieldType.Text,
      label: "MAP Prestarts",
      render: (itemData: any, item: any, cellData: any) => {
        const hasPreStartData = itemData;
        const assetId = item.mobileAsset ? item.mobileAsset.id : "";
        const url = `#/mobile-asset/${assetId}`;
        const mapStatusID = item.mapStatusID; // Check if mapStatusID exists
        // Map of status IDs to status text
        const statusMap: { [key: number]: string } = {
          1: "New",
          2: "Prework - Submitted with fault",
          3: "Prework Submitted",
          4: "Postwork - Submitted with fault",
          5: "Postwork Submitted",
        };

        return (
          assetId &&
          item._type_ > 0 && (
            <div>
              {(!mapStatusID || mapStatusID === 1) && (
                <a
                  href={hasPreStartData ? url : undefined}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button
                    style={{
                      backgroundColor: "#ffde01",
                      color: "black",
                      border: "1px solid gray",
                      borderRadius: "4px",
                      padding: "5px 10px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (assetId && !hasPreStartData) {
                        handleDialouge(
                          assetId,
                          item.mobileAsset.assetReadingKM,
                          item.mobileAsset.assetReadingHour,
                          item.mobileAsset.type.id,
                          item
                        );
                      }
                    }}
                  >
                    {hasPreStartData ? "Paper" : "+"}
                  </button>
                </a>
              )}

              {mapStatusID != null && mapStatusID !== '' && mapStatusID !== 1 && !hasPreStartData && (
                <button
                  style={{
                    backgroundColor: "green",
                    color: "white",
                    border: "1px solid gray",
                    borderRadius: "4px",
                    padding: "5px 10px",
                    cursor: "not-allowed",
                    marginLeft: "10px",
                  }}
                  onClick={(e) => {
                    e.preventDefault(); // Prevent any default action; button does nothing
                  }}
                >
                  {statusMap[mapStatusID] || `Unknown Status ${mapStatusID}`}
                </button>
              )}
            </div>
          )
        );
      },
    },
    {
      id: "mobile-asset-notused",
      key: "notUsed",
      type: FormTableFieldType.CheckboxGroup,
      label: "Asset Used",
      options: [{ value: "Asset Used", label: "Asset Used?" }],
      format: (cellData: any) => {
        if (cellData === true) {
          return "Asset Not Used";
        } else if (cellData == null) {
          return null;
        } else if (cellData === false) {
          return "Asset Used";
        }
        return cellData;
      },
    },
  ];

  if (type === 0) {
    delete tblFields[tblFields.length - 1];
  } else {
    delete tblFields[4];
    delete fieldKeys[4];
  }
  const addItem = (form: DRSMobileAssetRow) => {
    const retained = getRetained();
    const finalForm = transformMobileAssetData(form);
    const finalDataForm: any = finalForm;
    if (typeof finalDataForm.backupMachine != "undefined") {
      const backUpDataField: boolean =
        finalDataForm.backupMachine[0] == "Asset Used" ? true : false;
      finalDataForm.backupMachine = backUpDataField;
    }

    if (typeof finalDataForm.notUsed != "undefined") {
      const notUsedField: boolean =
        finalDataForm.notUsed[0] == "Asset Used" ? false : true;
      finalDataForm.notUsed = notUsedField;
    }

    const updatedData = [
      ...retained,
      {
        [updatingColumn]: {
          ...finalDataForm,
        },
      },
    ];

    update(updatedData);
  };
  const handleSaveToAll = async (form: DRSMobileAssetRow) => {
    try {
      await confirm({
        title: "Confirm",
        message: "Are you sure to apply Hours to all mobile assets?",
      });
      const finalForm = transformMobileAssetData(form);
      saveToAll(finalForm);
    } catch (e) {}
  };
  const saveToAll = async (form: DRSMobileAssetRow) => {
    const retained = getRetained();
    const updatedAssets = retained.map((asset: DRSMobileAssetPAE) => {
      if (isPlan) {
        return {
          ...asset,
          [updatingColumn]: {
            ...asset[updatingColumn],
            hours: form.hours,
            // backupMachine:form.backupMachine
          },
        };
      } else {
        let finalAsset = asset.edit.mobileAsset;
        if (!finalAsset) {
          finalAsset = asset.actual.mobileAsset;
        }
        return {
          ...asset,
          [updatingColumn]: {
            ...asset[updatingColumn],
            hours: form.hours,
            mobileAsset: finalAsset,
          },
        };
      }
    });

    if (form._no_ === undefined || form._type_ === undefined) {
      return;
    }
    const updatingIndex = form._no_;
    const retainedAsset = updatedAssets[updatingIndex];
    const data = [
      ...updatedAssets.slice(0, updatingIndex),
      {
        ...retainedAsset,
        [updatingColumn]: {
          ...form,
        },
      },
      ...updatedAssets.slice(updatingIndex + 1),
    ];
    update(data);
  };
  const editItem = (form: DRSMobileAssetRow) => {
    const finalForm = transformAssetData(form);
    const finalDataForm: any = finalForm;
    if (typeof finalDataForm.backupMachine != "undefined") {
      const backUpDataField: boolean =
        finalDataForm.backupMachine[finalDataForm.backupMachine.length - 1] ==
        "Y"
          ? true
          : false;
      finalDataForm.backupMachine = backUpDataField;
    }

    if (typeof finalDataForm.notUsed != "undefined") {
      let notUsedField: boolean = true;
      if (typeof finalDataForm.notUsed != undefined) {
        if (finalDataForm.notUsed) {
          notUsedField =
            finalDataForm.notUsed[finalDataForm.notUsed.length - 1] ==
            "Asset Used"
              ? false
              : true;
        }
      }

      finalDataForm.notUsed = notUsedField;
    }
    updateItemByValues(form, finalDataForm);
  };
  function handleRevert(form: DRSMobileAssetRow) {
    updateItemByValues(form, NULL_VALUES);
  }
  function handleMarkZero(form: DRSMobileAssetRow) {
    updateItemByValues(form, ZERO_VALUES);
  }
  const handleDelete = (form: DRSMobileAssetRow) => {
    const retained = getRetained();
    if (form._no_ === undefined || form._type_ === undefined) {
      return [];
    }
    const updatingIndex = form._no_;
    const data = [
      ...retained.slice(0, updatingIndex),
      ...retained.slice(updatingIndex + 1),
    ];

    update(data);
  };
  const getRetained = () => {
    return drs.mobileAssets.boral[normalizedType].assets || [];
  };
  const getUpdatingDetails = (
    originDetails: DRSMobileAssetPAE[],
    form: DRSMobileAssetRow,
    values: any
  ) => {
    if (form._no_ === undefined || form._type_ === undefined) {
      return originDetails;
    }
    const updatingIndex = form._no_;
    const retainedDetail = originDetails[updatingIndex];
    return [
      ...originDetails.slice(0, updatingIndex),
      {
        ...retainedDetail,
        [updatingColumn]: {
          ...values,
        },
      },
      ...originDetails.slice(updatingIndex + 1),
    ];
  };

  const updateItemByValues = (form: DRSMobileAssetRow, values: any) => {
    const retained = getRetained();
    update(getUpdatingDetails(retained, form, values));
  };
  const update = (data: any) => {
    genericUpdateDRSSection({
      sectionName: DRSSectionName.MobileAssets,
      path: `boral.${normalizedType}`,
      data: {
        assets: data,
        performed: true,
      },
      refresh,
      closeModal,
      alertDone,
      alertError,
      updateSectionViaPath,
    });
  };
  const getTableData = () => {
    const assets = drs.mobileAssets.boral[normalizedType].assets;
    if (!assets || !assets.length) {
      return [];
    }
    const doubled = doubleUp(assets, drsPageType);
    const doubledWithActions = doubled.map((data: any) =>
      mapMoreActions(data, drsPageType)
    );

    return doubledWithActions.map((asset: any) => {
      let assetDepotType = null;
      if (isEdit) asset._editable_ = true;
      if (isPlan) delete asset.notUsed;

      if (
        asset.mobileAsset &&
        asset.mobileAsset.depot &&
        asset.mobileAsset.depot.id
      ) {
        if (asset.mobileAsset.depot.id === depotId) {
          assetDepotType = {
            id: DRSAssetDepotType.MyDepot,
            description: "My Depot",
          };
        } else {
          assetDepotType = {
            id: DRSAssetDepotType.MyState,
            description: "My State",
          };
        }
      }

      return {
        ...asset,
        assetDepotType,
        assetDescription: asset.mobileAsset
          ? asset.mobileAsset.description
          : "",
        paperPreStart: {
          fileLocation: asset.fileLocation ?? "",
          cpFileName: asset.cpFileName ?? "",
          assetId: asset.mobileAsset ? asset.mobileAsset.id : "",
        },
      };
    });
  };
  return (
    <Wrapper>
      <FormPanel title={`Mobile Assets`}>
        <FormPAETable<DRSMobileAssetRow>
          fields={tblFields}
          data={getTableData()}
          PAEType={"mobileassets"}
          editable={true}
          isPlan={isPlan}
          isSelectable={true}
          onEditRow={(row: DRSMobileAssetRow) => {
            let rowBackupMachine: any = row.backupMachine;
            let backUpMachineData: any =
              rowBackupMachine == true ? ["Y"] : ["N"];

            let rowNotused: any = row.notUsed;
            let rowNotusedData: any =
              rowNotused == true ? ["Asset Not Used"] : ["Asset Used"];
            if (isPlan)
              openAndSetData({
                ...row,
                hours: { id: row.hours } as any,
                backupMachine: backUpMachineData,
              });
            else
              openAndSetData({
                ...row,
                hours: { id: row.hours } as any,
                backupMachine: backUpMachineData,
                notUsed: rowNotusedData,
              });
          }}
          showMoreActions={isPlan || isEdit}
          onRevert={handleRevert}
          onMarkZero={handleMarkZero}
          onDelete={handleDelete}
        />
        {(isPlan || isEdit) && (
          <MarginTop>
            <CompCreateButton
              onClick={() => {
                openAndSetData(null);
              }}
            >
              Add Mobile Asset
            </CompCreateButton>
          </MarginTop>
        )}
      </FormPanel>

      {open && (
        <Modal<DRSMobileAssetRow>
          maxWidth={"xs"}
          open={open}
          title="Mobile Assets"
          data={{
            rowData: modalData,
            fields: DRSMobileAssetsFields,
            columns: [
              {
                title: "Asset Information",
                fieldKeys: fieldKeys,
              },
            ],
            extra: {
              depotId,
              stateId,
            },
          }}
          onSuccess={onSave}
          onClose={closeModal}
          onCancel={closeModal}
          extraAction1Label={modalData === null ? "" : "Apply To All"}
          onExtraAction1={handleSaveToAll}
        />
      )}

      {isDialogueOpen && (
        <MobileAssetDocumentDialog
          open={isDialogueOpen}
          editMode={true}
          onCancel={() => {
            setisDialogueOpen(false);
            setCurrentAssetId(null);
            setCurrentReadingHour(null);
            setCurrentReadingKM(null);
            setCurrentType(null);
            setIsLoading(false);
          }}
          onClose={() => {
            setisDialogueOpen(false);
            setCurrentAssetId(null);
            setCurrentReadingHour(null);
            setCurrentReadingKM(null);
            setCurrentType(null);
            setIsLoading(false);
          }}
          onSuccess={() => {
            setisDialogueOpen(false);
            setCurrentAssetId(null);
            setCurrentReadingHour(null);
            setCurrentReadingKM(null);
            setCurrentType(null);
            setIsLoading(false);
          }}
          currentReadingHour={currentReadingHour}
          currentReadingKM={currentReadingKM}
          assetType={currentType}
          formId={currentAssetId}
          onSave={(
            selectedFiles: any,
            formId: any,
            readingKM: any,
            readingHr: any
          ) => {
            handleSave(
              selectedFiles,
              formId,
              readingKM,
              readingHr,
              currentForm
            );
          }}
          isLoader={isLoading}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div``;

export default PartDRSMobileAssets;
